export const s3bucket = "https://mindforce.s3.ap-south-1.amazonaws.com"

export const defaultPage = {
    total: 1,
    totalPages: 1,
    current_page: 1,
    perPage: 100,
    nextPage: null,
    previousPage: null
}

export const gstUrlStartComplete = "https://www.gotosample.com/manage-survey-response?status=10&identifier=[IDENTIFIER]"
export const gstUrlStartTerminate = "https://www.gotosample.com/manage-survey-response?status=20&identifier=[IDENTIFIER]"
export const gstUrlStartOverQuota = "https://www.gotosample.com/manage-survey-response?status=40&identifier=[IDENTIFIER]"
export const gstUrlStartQualityTerm = "https://www.gotosample.com/manage-survey-response?status=30&identifier=[IDENTIFIER]"
export const gstUrlStartSurveyClose = "https://www.gotosample.com/manage-survey-response?status=70&identifier=[IDENTIFIER]"
export const gstUrlResponse = "https://www.gotosample.com/survey-manage-response"