import React, { useEffect, useState } from 'react'
import { getSurveyLink, saveSurveyLink } from '../../../Api/Project';
import { uploadImageAPI } from '../../../ApiService';
import { format } from 'date-fns';
import { urlValidate } from '../../../Common/Regex';

function Survey_LInk(props) {
    const detail = props?.detail
    
    const [surveyLinkDetail,  setSurveyLinkDetail] = useState()
    const [editSurveyLink, setEditSurveylink] = useState();
    const [projectLinkType, setProjectLinkType] = useState('single-link')
    const [surveyLinkLive, setSurveyLinkLive] = useState()
    const [surveyLinkTest, setSurveyLinkTest] = useState()
    const [errorSurveyLinkLive, setErrorSurveyLinkLive] = useState()
    const [errorSurveyLinkTest, setErrorSurveyLinkTest] = useState()
    const [testSurveyLink, setTestSurveyLink] = useState()
    const [multiLinks, setMultiLinks] = useState()

    const [isOverRide, setIsOverRide] = useState(0);
    const [isTestLink, setIsTestLink] = useState(0);
    const [fileName, setFileName] = useState()
    const [filePath, setFilePath] = useState()
    const [filteTypeError, setFileTypeError] = useState()
    useEffect(() => {
        setEditSurveylink(props?.editSurveyLink)
    },[props?.editSurveyLink])
    useEffect(() => {
        getSurveyLinkDetail();
    },[])
    const getSurveyLinkDetail = async () => {
        let res = await getSurveyLink({ id: detail?.projectDetailID })
        console.log(res)
        if (res.status === 1) {
            setMultiLinks(res?.payload?.surveyLinkDetails)
            setSurveyLinkDetail(res?.payload?.surveyLinkType)
            setProjectLinkType(res?.payload?.surveyLinkType)
            setSurveyLinkLive(res?.payload?.surveyLinkDetails?.[0]?.liveUrl)
            setSurveyLinkTest(res?.payload?.surveyLinkDetails?.[0]?.testUrl)
            setTestSurveyLink(res?.payload?.testSurveyLink)
            props?.updateTestlink(res?.payload?.testSurveyLink)
        } else {
            setEditSurveylink()
            setProjectLinkType('single-link')
            setSurveyLinkLive()
            setSurveyLinkTest()
            setTestSurveyLink()
            props?.updateTestlink()
            if(res?.messageCode === 'EMPTY_RESPONSE'){
               
                setEditSurveylink(true)
                props?.updateEditSurvey(true)
            }
        }
    }

    const saveSurveyLinkDetail = async () => {
        let error = false;
        setErrorSurveyLinkLive()
        setErrorSurveyLinkTest()
        if(!surveyLinkLive){
            setErrorSurveyLinkLive("Required")
            error = true;
        }
        if(surveyLinkLive && !urlValidate(surveyLinkLive)){
            setErrorSurveyLinkLive("Invalid Url")
            error = true;
        }
        if(surveyLinkTest && !urlValidate(surveyLinkTest)){
            setErrorSurveyLinkTest("Invalid Url")
            error = true;
        }
        if(error){
            return false;
        }
        let payload = {
            surveyLinkType: projectLinkType,
            // surveyLinkType: 'single-link',
            liveSurveyUrl: surveyLinkLive,
            testSurveyUrl: surveyLinkTest
        }
        let res = await saveSurveyLink({ id: props?.detail?.projectDetailID, payload: payload })
        if (res.status === 1) {
            await setEditSurveylink(false)
            props?.updateEditSurvey(false)
            getSurveyLinkDetail()
        }
    }

    const handleLinkType = (e) => {
        setProjectLinkType(e.target.value)
    }

    const cancelEditSurvey = () =>{
        getSurveyLinkDetail()
        props?.updateEditSurvey(false)
        
    }

    const uploadMultiLinkFile = async(e)=>{
        setFileTypeError()
        if(e.target.files[0].type === 'text/csv'){
            uploadImage(e.target.files)
        }else{
            setFileTypeError("Inalid File")
        }
        
    }
    const uploadImage = async (data, index) => {
        if(data){
          data.path = 'property-images';
            let res = await uploadImageAPI('/project/upload-csv',data);
            if(res.status === 1){
                setFileName(res?.payload?.fileName)
                setFilePath(res?.payload?.filePath)
            }
        }
      }

      const handleIsOverRide = (e) => {
        setIsOverRide(e.target.value)
      }

      const handleIsTestLink = (e) => {
        const {value, checked} = e.target
        if(checked){
            setIsTestLink(1)
        }else{
            setIsTestLink(0)
        }
      }

      const submitMultiLink = async() => {
        if(!fileName || !filePath){
            setFileTypeError("Required")
            return false
        }
        let payload = {
            surveyLinkType: "multi-link",
            filePath: filePath,
            overwrite: isOverRide,
            testLink: isTestLink,
            fileName: fileName
        }
        
        let res = await saveSurveyLink({ id: props?.detail?.projectDetailID, payload: payload })
        if(res.status === 1){
            getSurveyLinkDetail()
        }
      }

    return (
        <div className="card-body">
            <div>
                <h5 className="header-title"><strong>Project Link Type</strong> </h5>
                <div className="form-check form-radio-outline form-radio-secondary mb-3">
                    <div className='d-flex'>
                        {!surveyLinkDetail ? (<>
                            <div className='col-md-3'>
                                <input className="form-check-input" type="radio" name="projectTypeLink" onChange={(e)=>handleLinkType(e)} value="single-link" defaultChecked={projectLinkType === 'single-link' ? true : false} />
                                <label className="form-check-label">
                                    Single Link
                                </label>
                            </div>
                            <div className='col-md-3'>
                                <input className="form-check-input" type="radio" name="projectTypeLink" value="multi-link" onChange={(e)=>handleLinkType(e)} defaultChecked={projectLinkType === 'multi-link' ? true : false} />
                                <label className="form-check-label">
                                    Multi Link
                                </label>
                            </div>
                        </>) : (<>
                        {projectLinkType === 'single-link' ? (<>
                            <div className='col-md-3'>
                                <input className="form-check-input" type="radio" name="projectTypeLink" onChange={(e)=>handleLinkType(e)} value="single-link" defaultChecked={projectLinkType === 'single-link' ? true : false} />
                                <label className="form-check-label">
                                    Single Link
                                </label>
                            </div>
                        </>) : (<>
                            {projectLinkType === 'multi-link' ? (<>
                                <div className='col-md-3'>
                                    <input className="form-check-input" type="radio" name="projectTypeLink" value="multi-link" onChange={(e)=>handleLinkType(e)} defaultChecked={projectLinkType === 'multi-link' ? true : false} />
                                    <label className="form-check-label">
                                        Multi Link
                                    </label>
                                </div>
                            </>) : (<></>)}
                        </>)}
                        </>)}
                        
                    </div>
                </div>
                {projectLinkType === 'single-link' ? (
                    <>
                    <div className="col-lg-12 col-md-12 col-12">
                        <span className="detail-list" ><strong>Live <span className="required_field">* {errorSurveyLinkLive}:</span></strong>
                            <br />

                            {editSurveyLink ? (<><input type="text" className="form-control" placeholder="Live" value={surveyLinkLive} onChange={(e) => setSurveyLinkLive(e.target.value)} />
                                https://surveys.surveyvikings.com/survey/selfserve/56d/230105?list=0&identifier=[IDENTIFIER]</>) : (<>
                                    {surveyLinkLive}
                                </>)}
                        </span>
                    </div>
                    <br />
                    <div className="col-lg-12 col-md-12 col-12">
                        <span className="detail-list" ><strong>Test <span className="required_field"> {errorSurveyLinkTest}:</span></strong>
                            <br />
                            {editSurveyLink ? (<>
                                <input type="text" className="form-control" placeholder="Test" value={surveyLinkTest} onChange={(e) => setSurveyLinkTest(e.target.value)} />
                                https://surveys.surveyvikings.com/survey/selfserve/56d/230105?list=0&identifier=[IDENTIFIER]</>) : (<>
                                    {surveyLinkTest}
                                </>)}
                        </span>
                    </div>
                    {editSurveyLink ? (<>
                        <div className="col-lg-12 col-md-12 col-12">

                            <button className="btn btn-primary" style={{ float: "right", marginLeft: "10px" }} onClick={() => saveSurveyLinkDetail()}>Update</button>
                            <button className="btn btn-secondary" style={{ float: "right" }} onClick={() => cancelEditSurvey()}>Cancel</button>
                        </div>
                    </>) : (<></>)}
                    </>
                ) : (<>
                {projectLinkType === 'multi-link' ? (<>
                {/* when link type is multi Link */}
                <div className='row'>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="fileUpload" class="form-label mb-0">File Upload <span className="required_field">*{filteTypeError}</span></label>
                            <input type="file" class="form-control" id="fileUpload" onChange={(e) => uploadMultiLinkFile(e)} />
                            <p style={{ color: "blue" }}><a href="/assets/MultiLink_Sample.csv" target="_blank">Download Sample CSV</a></p>
                        </div>
                    </div>
                    <div className='row'>
                        <div class="col-md-4 ">
                            <div class="mb-3 d-flex gap-2">
                                <div className="form-check form-radio-outline form-radio-secondary mb-3">
                                    <input className="form-check-input" type="radio" name="Overwrite" value="0" onChange={(e) => handleIsOverRide(e)} defaultChecked={true} />
                                    <label className="form-check-label">
                                        Append
                                    </label>
                                </div>
                                <div className="form-check form-radio-outline form-radio-secondary mb-3 mx-2">
                                    <input className="form-check-input" type="radio" name="Overwrite" value="1" onChange={(e) => handleIsOverRide(e)} />
                                    <label className="form-check-label">
                                        Overwrite
                                    </label>
                                </div>
                            </div>

                        </div>

                        <div className="col-md-4">
                            <div className="form-check mb-3">
                                <input className="form-check-input" type="checkbox" id={"TestLink"} value={1} onChange={(e) => handleIsTestLink(e)} />
                                <label className="form-check-label" for={"TestLink"}>
                                    Test Link
                                </label>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="text-end">
                                <button type="submit" className="btn btn-primary" disabled={filteTypeError ? true : false} onClick={() => submitMultiLink()}>Save</button>
                            </div>
                        </div>

                    </div>
                    {projectLinkType === 'multi-link' ? (<>
                    <div className='table-responsive'>
                        <table class="table table-striped table-hover table_client mt-3">
                            <thead>
                                <tr class="bg-primary" style={{ color: "white" }}>
                                    <th scope="col">S.No.</th>
                                    <th scope="col">FileName</th>
                                    <th scope="col">TotalLink</th>
                                    <th scope="col">UploadedLink</th>
                                    <th scope="col">RejectedLink</th>
                                    <th scope="col">TestLink</th>
                                    <th scope="col">Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {multiLinks?.map((row,i) => {
                                    return (<>
                                    <tr>
                                        <td>{i+1}</td>
                                        <td>{row?.fileName}</td>
                                        <td>{row?.totalLinks}</td>
                                        <td>{row?.validLinks}</td>
                                        <td>{row?.rejectedLinks}</td>
                                        <td>
                                            <div className="form-check ">
                                                <input className="form-check-input" type="checkbox" id='' checked={row?.isTestLink} />
                                            </div>
                                        </td>
                                        <td>{row?.createdOn ? (format(new Date(row?.createdOn*1000), 'd MMM, yyyy H:i')) : ""}</td>
                                    </tr>
                                    </>)
                                })}
                                
                            </tbody>
                        </table>
                    </div>
                    </>) : (<></>)}
                    
                </div>
                </>) : (<></>)}
                
                </>)}
            </div>
        </div>
    )
}

export default Survey_LInk