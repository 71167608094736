import React from "react"
import { useState } from "react"

function Pagination(props) {
    const [pagination, setPagination] = useState(props.pagination)
    const changePage = (p) =>{
        props.changePage({...pagination, ['current_page']:p})
    }

    return (
        <>
        {pagination?.totalPages > 1 ? (<>
            <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-end">
                <li class="page-item">
                    <a class={pagination?.previousPage ? ("page-link") : ("page-link disabled")} aria-label="Previous" disabled={pagination?.previousPage ? "" : "disabled"} onClick={() => changePage(pagination?.current_page - 1)}>
                        <span aria-hidden="true">&laquo;</span>
                        <span class="sr-only">Previous</span>
                    </a>
                </li>
                {(() => {
                    let pageHtml = [];
                    for (let i = 0; i < pagination?.totalPages; i++) {
                        pageHtml .push(<li class={props?.pagination?.current_page === i ? "page-item active" : "page-item"}><a class="page-link" onClick={() => changePage(i)}>{i+1}</a></li>)
                    }
                    return pageHtml
                })()}
                
                <li class="page-item">
                <a class={pagination?.nextPage ? ("page-link") : ("page-link disabled")} aria-label="Next" disabled={pagination?.nextPage ? "" : "disabled"} onClick={() => changePage(pagination?.current_page + 1)}>
                        <span aria-hidden="true">&raquo;</span>
                        <span class="sr-only">Next</span>
                    </a>
                </li>
            </ul>
        </nav>
        </>) : (<></>)}
        </>
    )

}
export default Pagination;