import React,{ useEffect, useState }from 'react'
import Footer from '../Layout/Footer'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import Multiselect from 'multiselect-react-dropdown';
import { masterData } from '../../Api/Master';
import { SupplierCreate, SupplierDetail, SupplierUpdate } from '../../Api/Supplier';
import { useNavigate } from "react-router-dom";
import {useLocation} from 'react-router-dom';

function Edit_supplier() {
    const location = useLocation();
    const navigate = useNavigate();
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const [countries, setCountries] = useState();
    const [selectedCountryName, setSelectedCountryName] = useState('')
    const [totalSelectedCountry, setTotalSelectedCountry] = useState()
    const [ApiCheck, setApiCheck] = useState(false);
    const [apiUrl, setApiUrl] = useState();
    const [apiKey, setApiKey] = useState();
    const [apiSecret, setApiSecret] = useState();
    const [apiBody, setApiBody] = useState()
    const [ActiveCheck, setActiveCheck] = useState(false);
    useEffect(() => {
        getCountries()
    },[])

    useEffect(() => {
        getDetails()
    },[])

    const getDetails = async() => {
        let res = await SupplierDetail({payload:location.state})
        if(res.status === 1){
            let apiDetail = res.payload[0].apiDetails;
            let allowedCountries = res.payload[0].allowedCountries
            let selectedCountryId = Array.isArray(allowedCountries) ? allowedCountries.map(x => x.id) : [];
            setSelectedCountryName(Array.isArray(allowedCountries) ? allowedCountries.map(x => x.name) : [])
            setTotalSelectedCountry(allowedCountries.length)
            res.payload[0].allowedCountriesID = selectedCountryId
            if(apiDetail?.apiURL || apiDetail?.apiKey || apiDetail?.apiBody || apiDetail?.secretKey){
                await setApiCheck(true)
                setApiUrl(apiDetail?.apiURL)
                setApiKey(apiDetail?.apiKey)
                setApiSecret(apiDetail?.secretKey)
                setApiBody(apiDetail?.apiBody)
            }
            await setForm(res.payload[0])
        }else{
            navigate("/supplier");
        }
    }

    const getCountries = async() => {
        let res = await masterData({moduleName:'country'})
        if(res.status === 1){
            setCountries(res.payload)
        }
    }
    const setField = (field, value) => {
        setForm({ ...form, [field]: value })
        if (!!errors[field])
            setErrors({ ...errors, [field]: null })
    }
    const validateForm = () =>{
        const{supplierName,website,countryID,completeURL,terminateURL,overQuotaURL,qualityTeamURL,surveyCloseURL} = form;
        const newErrors = {};
        if(!supplierName || supplierName === '') newErrors.supplierName = 'Please enter client name';
        if(!website || website === '') newErrors.website = 'Please enter this field';
        if(!countryID || countryID === 'Select Country') newErrors.countryID = 'Please select country';
        if(!completeURL || completeURL === '') newErrors.completeURL = 'Please enter this field';
        if(!terminateURL || terminateURL === '') newErrors.terminateURL = 'Please enter this field';
        if(!overQuotaURL || overQuotaURL === '') newErrors.overQuotaURL = 'Please enter this field';
        if(!qualityTeamURL || qualityTeamURL === '') newErrors.qualityTeamURL = 'Please enter this field';
        if(!surveyCloseURL || surveyCloseURL === '') newErrors.surveyCloseURL = 'Please enter this field';
        return newErrors
    }
    const handleUpdate = async (e) => {
        e.preventDefault();
        const formErrors = validateForm()
        if(Object.keys(formErrors).length > 0){
            setErrors(formErrors)
        }else{
            form.apiDetails= {
                url: apiUrl,
                key: apiKey,
                secretKey: apiSecret,
                body: apiBody
            }
            let res = await SupplierUpdate({payload:form})
            if(res.status === 1){
                navigate("/supplier");
            }else{
                setErrors({...errors,['submit']:res.message})
            }
        }
    }

    const onSelect = (selectedValues) => {
        let selectedCountryId = Array.isArray(selectedValues) ? selectedValues.map(x => x.id) : [];
        setField('allowedCountriesID',selectedCountryId)
        setSelectedCountryName(Array.isArray(selectedValues) ? selectedValues.map(x => ' '+x.name) : [])
        setTotalSelectedCountry(selectedCountryId.length)
    }

    const onRemove = (selectedValues) => {
        let selectedCountryId = Array.isArray(selectedValues) ? selectedValues.map(x => x.id) : [];
        setField('allowedCountriesID',selectedCountryId)
        setSelectedCountryName(Array.isArray(selectedValues) ? selectedValues.map(x => ' '+x.name) : [])
        setTotalSelectedCountry(selectedCountryId.length)
    }


    useEffect(() => {
        document.title = "Edit Supplier - Mindforce"
    },);

    const handleApiCheck = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setApiCheck(true)
        }

        if (!checked) {
            setApiCheck(false)
        }
    }

    const handleActiveCheck = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setActiveCheck(true)
        }

        if (!checked) {
            setActiveCheck(false)
        }
    }
    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div class="row">
                                <div class="col-12 col-lg-12">
                                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 class="mb-sm-0">Edit supplier</h4>
                                        <div class="page-title-right">
                                            <ol class="breadcrumb m-0">
                                                <li class="breadcrumb-item"><a >Supplier</a></li>
                                                <li class="breadcrumb-item active">Edit Supplier</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}

                            <div class="row">
                                <div class="col-xl-12 col-lg-12">
                                    <div class="card">
                                        {/* <!-- end card header --> */}
                                        <div class="card-body">
                                            <div class="">
                                            <form >
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="mb-3 required_field">{errors?.submit}</div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="form-check mb-3" style={{ float: "right" }}>
                                                                <input class="form-check-input" type="checkbox" id="formCheckActive" onClick={(e) => handleActiveCheck(e)} />
                                                                <label class="form-check-label" for="formCheckActive">
                                                                    Active
                                                                </label>
                                                            </div>
                                                            <div class="form-check mb-3" style={{ float: "right", marginRight:"10px" }}>
                                                                <input class="form-check-input" type="checkbox" id="formCheck1" onClick={(e) => handleApiCheck(e)} checked={ApiCheck} />
                                                                <label class="form-check-label" for="formCheck1">
                                                                    API
                                                                </label>
                                                            </div>
                                                            
                                                        </div>
                                                        <div class="col-md-4">
                                                            <div class="mb-3">
                                                                <label  class="form-label mb-0">Supplier Name <span class="required_field">*{errors.supplierName}</span></label>
                                                                <input type="text" class="form-control" placeholder="Enter supplier name" id="supplierName" defaultValue={form?.supplierName} onChange={(e) => setField('supplierName', e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <div class="mb-3">
                                                                <label  class="form-label mb-0">Supplier Website <span class="required_field">*{errors.website}</span></label>
                                                                <input type="text" class="form-control" placeholder="Enter website url" id="website" defaultValue={form?.website} onChange={(e) => setField('website', e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <div class="mb-3">
                                                                <label for="ForminputState" class="form-label mb-0">Country <span class="required_field">*{errors.countryID}</span></label>
                                                                <select  className="form-select" data-choices data-choices-sorting="true" id="Country" onChange={(e) => setField('countryID', parseInt(e.target.value))} >
                                                                    <option disabled selected>Select Country</option>
                                                                    {countries?.map((country) => {
                                                                        return (<option value={country.id} selected={form?.countrySlug === country.slug ? true : false}>{country.name}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-4">
                                                            <div class="mb-3">
                                                                <label  class="form-label mb-0">Email ID </label>
                                                                <input type="email" className="form-control" placeholder="Enter email" id="emailID"  defaultValue={form?.email} onChange={(e) => setField('email', e.target.value)} />
                                                            </div>
                                                        </div>
                                                        {/* <!--end col--> */}
                                                        <div class="col-md-4">
                                                            <div class="mb-3">
                                                                <label for="ProjectManager" class="form-label mb-0">Contact Number </label>
                                                                <input type="text" className="form-control" placeholder="Enter number" id="contactNumber"  defaultValue={form?.contactNumber} onChange={(e) => setField('contactNumber', e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <div class="mb-3">
                                                                <label for="ProjectManager" class="form-label mb-0">Panel Size </label>
                                                                <input type="text" className="form-control" placeholder="Enter number" id="panelSize"  defaultValue={form?.panelSize} onChange={(e) => setField('panelSize', parseInt(e.target.value))} />
                                                            </div>
                                                        </div>

                                                        <div class="col-md-4">
                                                            <div class="mb-3">
                                                                <label  class="form-label mb-0">Complete <span class="required_field">*{errors.completeURL}</span></label>
                                                                <input type="text" class="form-control" placeholder="Enter " id="completeURL" value={form?.completeURL} onChange={(e) => setField('completeURL', e.target.value)} />
                                                                <span class="max_char">Link ex. https://gts.bytesbrick.com/thanks?status=c&identifier=[IDENTIFIER]</span>

                                                            </div>
                                                        </div>
                                                        {/* <!--end col--> */}
                                                        <div class="col-md-4">
                                                            <div class="mb-3">
                                                                <label for="ProjectManager" class="form-label mb-0"> Terminate <span class="required_field">*{errors.terminateURL}</span></label>
                                                                <input type="text" class="form-control" placeholder="Enter"  id="terminateURL" value={form?.terminateURL} onChange={(e) => setField('terminateURL', e.target.value)} />
                                                                <span class="max_char">Link ex. https://gts.bytesbrick.com/thanks?status=c&identifier=[IDENTIFIER]</span>

                                                            </div>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <div class="mb-3">
                                                                <label for="ProjectManager" class="form-label mb-0">Over Quota <span class="required_field">*{errors.overQuotaURL}</span></label>
                                                                <input type="text" class="form-control" placeholder="Enter " id="overQuotaURL" value={form?.overQuotaURL} onChange={(e) => setField('overQuotaURL', e.target.value)}/>
                                                                <span class="max_char">Link ex. https://gts.bytesbrick.com/thanks?status=c&identifier=[IDENTIFIER]</span>

                                                            </div>
                                                        </div>
                                                        {/* <!--end col--> */}
                                                        <div class="col-md-4">
                                                            <div class="mb-3">
                                                                <label  class="form-label mb-0">Quality Team <span class="required_field">*{errors.qualityTeamURL}</span></label>
                                                                <input type="text" class="form-control" placeholder="Enter " id="qualityTeamURL" value={form?.qualityTeamURL} onChange={(e) => setField('qualityTeamURL', e.target.value)} />
                                                                <span class="max_char">Link ex. https://gts.bytesbrick.com/thanks?status=c&identifier=[IDENTIFIER]</span>

                                                            </div>
                                                        </div>
                                                        {/* <!--end col--> */}
                                                        <div class="col-md-4">
                                                            <div class="mb-3">
                                                                <label for="ProjectManager" class="form-label mb-0">Survey Close <span class="required_field">*{errors.surveyCloseURL}</span></label>
                                                                <input type="text" class="form-control" placeholder="Enter " id="surveyCloseURL" value={form?.surveyCloseURL} onChange={(e) => setField('surveyCloseURL', e.target.value)} />
                                                                <span class="max_char">Link ex. https://gts.bytesbrick.com/thanks?status=c&identifier=[IDENTIFIER]</span>

                                                            </div>
                                                        </div>
                                                        {/* <div class="col-md-4">
                                                            <div class="mb-3">
                                                                <label for="ProjectManager" class="form-label mb-0">Post Back Url </label>
                                                                <input type="text" class="form-control" placeholder="Enter " id="postbackURL" value={form?.postbackURL} onChange={(e) => setField('postbackURL', e.target.value)} />
                                                                <span class="max_char">Link ex. https://gts.bytesbrick.com/thanks?status=c&identifier=[IDENTIFIER]</span>
                                                            </div>
                                                        </div> */}

                                                        {/* <!--end col--> */}
                                                        <div class="col-md-12">
                                                            <div class="mb-3">
                                                                <label for="aboutSupplier" class="form-label mb-0">About Supplier </label>
                                                                <textarea class="form-control" id="aboutSupplier" rows="3" onChange={(e) => setField('aboutSupplier', e.target.value)} defaultValue={form?.aboutSupplier}>{form?.aboutSupplier}</textarea>
                                                                <span class="max_char">Maximum limit of characters 250</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <div class="mb-3 optionListContainer_div">
                                                                <label for="ForminputState" class="form-label mb-0">Allowed Countries <span class="required_field">*</span></label>
                                                                <Multiselect
                                                                    showArrow
                                                                    options={countries}
                                                                    displayValue="name"
                                                                    onSelect={onSelect}
                                                                    onRemove={onRemove}
                                                                    renderOptionList={true}
                                                                    placeholder={`${totalSelectedCountry} selected`}
                                                                    selectedValues={form?.allowedCountries}
                                                                    // defaultValue = { defaultCountry.map(ele => ele) }
                                                                />
                                                            </div>
                                                        </div>
                                                        {/* <div class="col-md-6">
                                                            <div class="mb-3">
                                                                <label for="ProjectManager" class="form-label mb-0">Selected Countries </label>
                                                                <input type="text" class="form-control country_allowed" value={selectedCountryName + " "} placeholder="Enter size" id="ProjectManager" />
                                                            </div>
                                                        </div> */}
                                                        {ApiCheck &&
                                                        <>
                                                            <div class="col-md-6">
                                                                <div class="mb-3">
                                                                    <label for="ProjectManager" class="form-label mb-0">API Url </label>
                                                                    <input type="text" className="form-control" placeholder="Enter " value={apiUrl} onChange={(e) => setApiUrl(e.target.value)}  />
                                                                </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="mb-3">
                                                                        <label for="ProjectManager" class="form-label mb-0">API Key </label>
                                                                        <input type="text" className="form-control" placeholder="Enter "  value={apiKey} onChange={(e) => setApiKey(e.target.value)}  />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="mb-3">
                                                                        <label for="ProjectManager" class="form-label mb-0">Secret Key </label>
                                                                        <input type="text" className="form-control" placeholder="Enter "  value={apiSecret} onChange={(e) => setApiSecret(e.target.value)} />
                                                                    </div>
                                                                </div>


                                                                <div class="col-md-12">
                                                            <div class="mb-3">
                                                                <label for="apiBody" class="form-label mb-0">API Body </label>
                                                                <textarea class="form-control" id="apiBody" rows="3" value={apiBody} onChange={(e)=>setApiBody(e.target.value)}></textarea>
                                                            
                                                            </div>
                                                        </div>

                                                                </>

                                                        }
                                                        <div class="col-lg-12">
                                                            <div class="text-end">
                                                                <button type="submit" onClick={handleUpdate} class="btn btn-primary mx-2">Submit</button>
                                                                <button type="Cancel" class="btn btn-secondary" onClick={() => getDetails}>Cancel</button>
                                                            </div>
                                                        </div>

                                                        {/* <!--end col--> */}
                                                    </div>
                                                    {/* <!--end row--> */}
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end col --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Edit_supplier