import React, { useEffect, useState } from "react";
import { CCollapse, CButton, CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter } from '@coreui/react'
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css';
import { get, postAPI } from "../../../ApiService";

const defaultMessge = "Please remember to all answer to our questions carefully and thoughtfully. Your survey completion will be rejected and your reward will not be paid in the event that you demonstrate inattentive behaviors such as speeding through our survey or entering false information!";

function PreScreenMessage(props){
    const [visiblePreScreenMessageModal, setVisiblePreScreenMessageModal] = useState(false)
    const [showMessage, setShowMessage] = useState();
    const [preScreenMessage, setPreScreenMessage] = useState(defaultMessge)

    useEffect(() => {
        getPrescreenMessage()
    },[])

    const getPrescreenMessage = async () => {
        let res = await get('/project/prescreen/get-message/'+props?.detail?.projectDetailID)

        if (res?.status === 1) {
            await setPreScreenMessage(res.payload?.message?.toString('html'))
            await setShowMessage(res?.payload?.status)
        }
        
    }

    const handleMessage = (e) => {
        setPreScreenMessage(e?.toString('html'));
    };

    const handleShowMessage = (e) => {
        let { checked } = e.target
        let val = 0;
        if (checked) {
            val = 1;
        }
        
        setShowMessage(val);
    }

    const addPrescreenMessage = async () => {
        let payload = {
            projectDetailID: props?.detail?.projectDetailID,
            message: preScreenMessage,
            showMessage: showMessage
        }

        console.log('payload',payload)

        let res = await postAPI('/project/prescreen/save-message', payload)
        console.log(res)
        if (res.status === 1) {
            props?.visible()
        }
    }

    return (<>
        
            <CModalHeader>
                <CModalTitle>PreScreen Message</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <div className="form-check mb-8">
                    <input className="form-check-input formcheckedbg" type="checkbox" checked={showMessage===1 ? true : false} onChange={(e) => handleShowMessage(e)} />
                    <label className="form-check-label mx-2" >
                        Show Message
                    </label>
                </div>
                {/* <RichTextEditor 
            value={preScreenMessage}
            onChange={(e)=>handleMessage(e)}
            style={{ minHeight: 410 }} /> */}
                <ReactQuill theme="snow" value={preScreenMessage} onChange={(e)=>handleMessage(e)} />

            </CModalBody>
            <CModalFooter>
                <CButton color="secondary" onClick={() => setVisiblePreScreenMessageModal(false)}>Close</CButton>
                <CButton color="primary" onClick={() => addPrescreenMessage()}>Save changes</CButton>
            </CModalFooter>
        
    </>)
}
export default PreScreenMessage