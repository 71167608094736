import React from 'react'
import Footer from '../Layout/Footer'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import { useEffect } from 'react'

function Block_ip_list() {
    useEffect(() => {

        document.title = "Blocked IPs - Mindforce"



    },);
    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            <div class="row">
                                <div class="col-12">
                                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 class="mb-sm-0">Blocked IP List</h4>

                                        <div class="page-title-right">
                                            <ol class="breadcrumb m-0">
                                                <li class="breadcrumb-item"><a >Support</a></li>
                                                <li class="breadcrumb-item active">Blocked IP List</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}

                            <div class="row">
                                <div class="col-xl-12 col-lg-12">
                                    <div class="card">

                                        {/* <!-- end card header --> */}

                                        <div class="card-body">
                                            <div class="report">
                                                <form >
                                                    <div class="row">
                                                       

                                                     
                                                        <div class='row justify-content-between mt-3'>
                                                            <div class="col-md-2 col-lg-2">
                                                                <div class="mb-3 d-flex page_size_support">
                                                                    <label for="ProjectManager" class="form-label mb-0 mt-1 ">Page Size : </label>
                                                                    <select id="ForminputState" class="form-select" data-choices data-choices-sorting="true">
                                                                        <option value={""} disabled selected>1</option>
                                                                        <option>10</option>
                                                                        <option>20</option>
                                                                        <option>30</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 col-lg-4 ">
                                                                <div class="text-end">
                                                                    <div class='search '>
                                                                        <input type="text" className="form-control" placeholder="Search..." autocomplete="off" id="search-options" value="" />

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <!--end col--> */}


                                                        {/* <!--end col--> */}

                                                        <table class="table table-striped table-hover table_client mt-3">
                                                            <thead>
                                                                <tr class="bg-primary" style={{ color: "white" }}>
                                                                    <th scope="col">Sr. No.</th>
                                                                    
                                                                    <th scope="col">IP</th>
                                                                    <th scope="col">Block Date</th>
                                                                    <th scope="col">Comments</th>
                                                                   
                                                                    <th scope="col">Action</th>
                                                                   
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">1</th>

                                                                    
                                                                    <td>xxxx xxx xx</td>
                                                                    <td>22-jan-2023</td>
                                                                    <td>ABC</td>
                                                                    <td> <i class="ri-edit-2-line"></i></td>
                                                                   

                                                                </tr>


                                                            </tbody>
                                                        </table>




                                                        {/* <!--end col--> */}
                                                    </div>
                                                    {/* <!--end row--> */}
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                {/* <!-- end col --> */}









                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Block_ip_list