import React, { useState, useEffect } from "react";
import { CModalHeader, CModalTitle, CModalBody } from '@coreui/react'
import { get, postAPI } from "../../../ApiService";

function PreScreenMappedQueView(props){
    const [questionDetail, setQuestionDetail] = useState(); 
    useEffect(() => {
        if(props?.preScreenerQuestionID){
            getTempQueDetail()
        }
    },[])

    const getTempQueDetail = async()=>{
        let payload = {
            preScreenerQuestionID: props?.preScreenerQuestionID,
            projectDetailID: props?.detail?.projectDetailID,
        }
        let res = await get('/project/precsreen/get-mapped-question-details/'+props?.detail?.projectDetailID+'/'+props?.preScreenerQuestionID)
        if(res?.status === 1){
            setQuestionDetail(res?.payload?.[0])
        }
    }
    return (<>
    <CModalHeader>
        <CModalTitle>Option</CModalTitle>
    </CModalHeader>
    <CModalBody>
        
            <div class="row g-3">
            <div className='table-responsive'>
                <table class="table table-striped table-hover table_client mt-3">
                    <thead>
                        <tr class="bg-primary" style={{ color: "white" }}>
                            <th scope="col">Id</th>
                            <th scope="col" style={{textAlign:"left"}}>Option</th>
                            <th scope="col" style={{textAlign:"left"}}>Validate</th>
                            <th scope="col" style={{textAlign:"left"}}>Quota</th>
                        </tr>
                    </thead>
                    <tbody>
                        {questionDetail?.mappedOptions?.map((o, i) => {
                            return (<>
                                <tr>
                                    <td>{i + 1}</td>
                                    <td style={{textAlign:"left"}}>{o?.option}</td>
                                    <td style={{textAlign:"left"}}>
                                        <input type="checkbox" checked={o?.validate === 1 ? true : false} />
                                    </td>
                                    <td style={{textAlign:"left"}}>{o?.quota}</td>
                                </tr>
                            </>)
                        })}

                    </tbody>
                </table>
            </div>
        </div>
        
    </CModalBody>
    </>)
}

export default PreScreenMappedQueView