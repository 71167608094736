import React from 'react'
import Footer from '../../Layout/Footer'
import Header from '../../Layout/Header'
import Sidebar from '../../Layout/Sidebar'
import Select from 'react-dropdown-select';
import { useEffect, useState } from 'react'
import { downloadPrescreenReport } from "../../../Api/PreScreen";
import { getAllProjectReport } from '../../../Api/Report'
import { getSngleProjectReport } from "../../../Api/Project";
import {getGroupProjectReport} from "../../../Api/ProjectGroup"

function Project_report() {
    useEffect(() => {
        document.title = "Project Report - Mindforce"
    },);
    const [lists, setLists] = useState();
    const [selProjectID, setProjectID] = useState();
    const [type, setType] = useState('project');

    useEffect(() => {
        getReport();
    }, []);

    const downloadReport = async (e) => {
        // console.log('type', type);
        e.preventDefault();
        if(type === 'project'){
            let prjIds = selProjectID?.split('-');
            let res;
            if(prjIds[0] === 'single')
            res = await getSngleProjectReport({ id: prjIds[1] })
            else
            res = await getGroupProjectReport({ id: prjIds[1] })
            if (res.status === 1) {
                window.open(res?.payload?.reportURL);
                //downloadFromURL(res?.payload?.reportURL, 'application/vnd.ms-excel', 'a.xlsx')
            }
        }else if(type === 'prescreen'){
            let payload = {
                projectDetailId: selProjectID
            }
            let res = await downloadPrescreenReport({payload})
            if (res.status === 1) {
                window.open(res?.payload?.reportURL)
            }
        }
        
    }

    const getReport = async() => {
        let res = await getAllProjectReport();
        if (res.status === 1) {
            let prjList = [];

            res.payload?.map((row) => {
                    prjList.push(
                        {
                            "projectDetailID": row.projectType + '-' + row.projectDetailID,
                            "projectDetailFullName": row.projectDetailCode + ': ' + row.projectDetailFullName + ' - ' + row.countryName,
                        }
                    )
                }
            )
            setLists(prjList);
        } else 
        setLists([]);
    }
    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            <div class="row">
                                <div class="col-12 col-lg-12">
                                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 class="mb-sm-0">Project Report</h4>

                                        <div class="page-title-right">
                                            <ol class="breadcrumb m-0">
                                                <li class="breadcrumb-item"><a >Report</a></li>
                                                <li class="breadcrumb-item active">Project Report</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}

                            <div class="row">
                                <div class="col-xl-12 col-lg-12">
                                    <div class="card">

                                        {/* <!-- end card header --> */}

                                        <div class="card-body">
                                            <div class="report">
                                                <form >
                                                    <div class="row">
                                                   
                                                    <div class="col-md-4">
                                                            <div class="mb-3">
                                                                <label for="ForminputState" class="form-label mb-0">Project <span class="required_field">*</span></label>
                                                                <Select
                                                                    className="form-control"
                                                                    options={lists}
                                                                    labelField="projectDetailFullName"
                                                                    valueField="projectDetailID"
                                                                    searchBy="projectDetailFullName"
                                                                    onChange={(values) => setProjectID(values[0]?.projectDetailID)}
                                                                    placeholder="Select Project"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-2">
                                                            <div class="form-check mb-3 rmPL">
                                                                <input class="form-check-input" name="rdoReport" type="radio" id="formCheckProject" checked={true} onClick={(e) => setType('project')} />
                                                                <label class="form-check-label" for="formCheck1">
                                                                    Project Report <span class="required_field">*</span>
                                                                 </label>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <div class="form-check mb-3 rmPL">
                                                                <input class="form-check-input" name="rdoReport" type="radio" id="formCheckPreScreen"  onClick={(e) => setType('prescreen')} />
                                                                <label class="form-check-label" for="formCheck1">
                                                                    PreScreen Report <span class="required_field">*</span>
                                                                 </label>
                                                            </div>
                                                        </div>
                                                        {/* <!--end col--> */}
                                                       
                                                     
                                                        {/* <!--end col--> */}
                                                       


                                                        <div class="col-lg-12">
                                                            <div class="text-end">
                                                                <button class="btn btn-primary " onClick={(e) => downloadReport(e)}>Download</button>
                                                               
                                                            </div>
                                                        </div>


                                                        {/* <!--end col--> */}
                                                    </div>
                                                    {/* <!--end row--> */}
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                {/* <!-- end col --> */}









                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Project_report