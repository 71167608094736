import React, { useEffect, useState } from "react";
import { format } from 'date-fns';
import moment from 'moment'
import { masterData } from "../../../Api/Master";

function TabGroupChildDetail(props){
    let tFieldIR = 0;
    let tConversion = 0;
    let tDropRate = 0
    let tFieldLOI = 0;
    let tTotal = 0;
    let tComplete = 0;
    let tTerminate = 0
    let tOverQuota = 0
    let tQualityTerm = 0
    let tDropOut = 0
    let tActive = 0
    let tReject = 0
    let tDuplicateUser = 0
    let tDuplicateIp = 0
    let tGeoIp = 0
    let tDeviceType = 0
    let tBlockedIP = 0
    let tSpeeder = 0
    let tExclude = 0
    let tPsStart = 0
    let tPsTerminate = 0
    let tPsOverQuota = 0
    let tPsDropOut = 0
    let tSurveyClose = 0
    let tInActie = 0
    let tTrafficDisabled = 0
    let tProjectCompleteExhausted = 0
    let tProjectClickExhausted = 0
    let tSupplierCompleteExhausted = 0
    let tSupplierClickExhausted = 0
    let tMultiLinkNotAvailable = 0
    let tIdentifierNotExist = 0
    let tFroudUser = 0
    let tTSFailure = 0;
    let tRDFailure = 0;
    let tTestLink = 0;
    const detail = props?.detail
    const childDetail = props?.detail?.childProjects?.[0]
    // const projectFilters = props?.detail?.projectFilters;
    // const securityFilters = props?.detail?.securityFilters;
    // const deviceFilters = props?.detail?.deviceFilters;
    const mappedSupplierList = props?.mappedSupplierList
    const redirectLinks = props?.redirectLinks

    const [projectFilters, setProjectFilters] = useState()
    const [securityFilters, setSecurityFilters] = useState()
    const [deviceFilters, setDeviceFilters] = useState()

    useEffect(() => {
        getProjectFilters()
        getSecurityFilters()
        getDeviceFilter()
    },[])

    const getProjectFilters = async() => {
        let res = await masterData({moduleName:'project-filters'})
        if(res.status === 1){
            setProjectFilters(res.payload)
        }
    }

    const getSecurityFilters = async() => {
        let res = await masterData({moduleName:'security-filters'})
        if(res.status === 1){
            setSecurityFilters(res.payload)
        }
    }

    const getDeviceFilter = async() => {
        let res = await masterData({moduleName:'device-filters'})
        if(res.status === 1){
            setDeviceFilters(res.payload)
        }
    }

    const copyRedirectLinkText = (e, url, index) => {
        navigator.clipboard.writeText(url);
        var redirectUrlBG = document.getElementsByClassName("redirectUrl");
        var len = redirectUrlBG.length;

        for (var i = 0; i < len; i++) {
            if (i === index) {
                redirectUrlBG[i].style.backgroundColor = "#dfdff8";
            } else {
                redirectUrlBG[i].style.backgroundColor = "transparent";
            }

        }
    }
    return (<>
    <div className="card-body">
        <div>
            <div className="row boxShadow">
                <div className="col-md-6 col-lg-6">
                    <div className="row padTop">
                        <div className="col-lg-4 col-md-4 col-5 pl pr-0">
                            <input type="hidden" autocomplete="off" value="2997" />
                            <span className="fontBold">ProjectCode</span><span className="fontBold pull-right">:</span>
                        </div>
                        <div className="col-lg-8 col-md-8 col-7">
                            <span className="detail-list" >{childDetail?.projectCode}</span>
                        </div>
                    </div>
                    <div className="row padTop">
                        <div className="col-lg-4 col-md-4 col-5 pl pr-0">
                            <span className="fontBold">ProjectName</span><span className="fontBold pull-right">:</span>
                        </div>
                        <div className="col-lg-8 col-md-8 col-7">
                            <span className="detail-list" id="ProjectName">{childDetail?.fullName}</span>
                        </div>
                    </div>

                    <div className="row padTop">
                        <div className="col-lg-4 col-md-4 col-5 pl pr-0">
                            <span className="fontBold"> ProjectCategory </span><span className="fontBold pull-right">:</span>
                        </div>
                        <div className="col-lg-8 col-md-8 col-7">
                            <span className="detail-list" id="ProjectCategory">{detail?.categoryName}</span>
                        </div>
                    </div>
                    <div className="row padTop">
                        <div className="col-lg-4 col-md-4 col-5 pl pr-0">
                            <span className="fontBold">LOI (Min) </span><span className="fontBold pull-right">:</span>
                        </div>
                        <div className="col-lg-8 col-md-8 col-7">
                            <span className="detail-list" id="AverageLoi">{detail?.loi}</span>
                        </div>
                    </div>
                    <div className="row padTop">
                        <div className="col-lg-4 col-md-4 col-5 pl pr-0">
                            <span className="fontBold">IR (%)</span><span className="fontBold pull-right">:</span>
                        </div>
                        <div className="col-lg-8 col-md-8 col-7">
                            <span className="detail-list" id="Ir">{detail?.ir}</span>
                        </div>
                    </div>

                    <div className="row padTop">
                        <div className="col-lg-4 col-md-4 col-5 pl pr-0">
                            <span className="fontBold">StartDate</span><span className="fontBold pull-right">:</span>
                        </div>
                        <div className="col-lg-8 col-md-8 col-7">
                            <span className="detail-list" id="StartDate">{detail ? (<>{format(new Date(detail?.startDate * 1000), 'dd-MMM-yyyy')}</>) : (<></>)}</span>
                        </div>
                    </div>

                    <div className="row padTop">
                        <div className="col-lg-4 col-md-4 col-5 pl pr-0">
                            <span className="fontBold"> EndDate</span><span className="fontBold pull-right">:</span>
                        </div>
                        <div className="col-lg-8 col-md-8 col-7">
                            <span className="detail-list" id="EndDate">{detail ? (<>{format(new Date(detail?.endDate * 1000), 'dd-MMM-yyyy')}</>) : (<></>)}</span>
                        </div>
                    </div>
                    <div className="row padTop">
                        <div className="col-lg-4 col-md-4 col-5 pl pr-0">
                            <span className="fontBold"> Currency</span><span className="fontBold pull-right">:</span>
                        </div>
                        <div className="col-lg-8 col-md-8 col-7">
                            <span className="detail-list" id="CurrencyCode">{detail?.currencyName}</span>
                        </div>
                    </div>
                    <div className="row padTop">
                        <div className="col-lg-4 col-md-4 col-5 pl pr-0">
                            <span className="fontBold"> Description</span><span className="fontBold pull-right">:</span>
                        </div>
                        <div className="col-lg-8 col-md-8 col-7">
                            <span className="detail-list" id="Description">{detail?.description}</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-6">
                    <div className="row padTop">
                        <div className="col-lg-4 col-md-4 col-5 pl pr-0">
                            <span className="fontBold">ClientCode</span><span className="fontBold pull-right">:</span>
                        </div>
                        <div className="col-lg-8 col-md-8 col-7">
                            <span className="detail-list" id="ClientCode">{detail?.clientCode}</span>
                        </div>
                    </div>
                    <div className="row padTop">
                        <div className="col-lg-4 col-md-4 col-5 pl pr-0">
                            <span className="fontBold">ClientName</span><span className="fontBold pull-right">:</span>
                        </div>
                        <div className="col-lg-8 col-md-8 col-7">
                            <span className="detail-list" id="ClientName">{detail?.clientName}</span>
                        </div>
                    </div>
                    <div className="row padTop">
                        <div className="col-lg-4 col-md-4 col-5 pl pr-0">
                            <span className="fontBold">Country</span><span className="fontBold pull-right">:</span>
                        </div>
                        <div className="col-lg-8 col-md-8 col-7">
                            <span className="detail-list" id="CountryName">{detail?.countryName}</span>
                        </div>
                    </div>
                    <div className="row padTop">
                        <div className="col-lg-4 col-md-4 col-5 pl pr-0">
                            <span className="fontBold">Language</span><span className="fontBold pull-right">:</span>
                        </div>
                        <div className="col-lg-8 col-md-8 col-7">
                            <span className="detail-list" id="PreScreensLanguage">{detail?.languageName}</span>
                        </div>
                    </div>
                    <div className="row padTop">
                        <div className="col-lg-4 col-md-4 col-5 pl pr-0">
                            <span className="fontBold">SampleSize</span><span className="fontBold pull-right">:</span>
                        </div>
                        <div className="col-lg-8 col-md-8 col-7">
                            <span className="detail-list" id="SampleSize">{detail?.sampleSize}</span>
                        </div>
                    </div>

                    <div className="row padTop">
                        <div className="col-lg-4 col-md-4 col-5 pl pr-0">
                            <span className="fontBold">ClickQuota </span><span className="fontBold pull-right">:</span>
                        </div>
                        <div className="col-lg-8 col-md-8 col-7">
                            <span className="detail-list" id="RespondentClickQuota">{detail?.respondentClickQuota}</span>
                        </div>
                    </div>

                    <div className="row padTop">
                        <div className="col-lg-4 col-md-4 col-5 pl pr-0">
                            <span className="fontBold">ProjectCPI</span><span className="fontBold pull-right">:</span>
                        </div>
                        <div className="col-lg-8 col-md-8 col-7">
                            <span className="detail-list" id="Cpi">{detail?.projectCPI}</span>
                        </div>
                    </div>
                    <div className="row padTop">
                        <div className="col-lg-4 col-md-4 col-5 pl pr-0">
                            <span className="fontBold">SupplierCPI</span><span className="fontBold pull-right">:</span>
                        </div>
                        <div className="col-lg-8 col-md-8 col-7">
                            <span className="detail-list" id="SupplierCpiValue">{detail?.supplierCPI}</span>
                        </div>
                    </div>

                </div>



            </div>

            <hr />
            <div className="prNotes notesWrapper">
                <h5 className="header-title"><strong>Notes </strong> </h5>
                <div className="boxShadow1">
                    <div className="col-md-12 col-lg-12 pl-0">
                        <p><span className="detail-list" id="Note">{detail?.notes}</span></p>
                    </div>
                </div>
            </div>
            <hr />
            <h1 className="project_filter">Project Filter</h1>
            <div className='row'>
                {projectFilters?.map((row, i) => {
                    
                    return (
                        
                        <div className="col-md-3">
                            <div className="form-check mb-3" style={{ display: "flex" }}>
                                <input className="form-check-input" type="checkbox" id={"projectFilter" + row.id} value={row.id}
                                    checked={row?.id === childDetail?.projectFilters?.[i]?.id && childDetail?.projectFilters?.[i]?.status === 1 ? true : false}
                                    style={{ marginRight: "4px" }}
                                />
                                <label className="form-check-label" for={"projectFilter" + row.id} style={{ marginRight: "4px" }}>
                                    {row.name}
                                </label>
                                {
                                    row.slug =="unique-ip" &&
                                    <>{childDetail?.projectFilters?.[i]?.value ? (<>{ ' : '+childDetail?.projectFilters?.[i]?.value?.toString()}</>) : (<></>)}</>
                                    
                                }
                                    {
                                    row.slug =="speeder" && <>{childDetail?.projectFilters?.[i]?.value ? (<>{ ' : '+childDetail?.projectFilters?.[i]?.value?.toString()}</>) : (<></>)}</>
                                    // <input type="text" className="small-input" pattern="{0-9}" placeholder="" style={{height:"20px", width:"50px"}} id="Speeder" defaultValue={detail?.projectFilters?.[i].value?.toString()}  /> 
                                }

                               
                            </div>
                        </div>
                    )
                })}
            </div>
            <hr />
            <h1 className="project_filter">Security Filter</h1>
            <div className='row'>
                {securityFilters?.map((row, i) => {
                    return (
                        <div className="col-md-3">
                            <div className="form-check mb-3">
                                <input className="form-check-input" type="checkbox" id={"securityFilter" + row?.id} value={row?.id}
                                    checked={row?.id === childDetail?.securityFilters?.[i]?.id && childDetail?.securityFilters?.[i]?.status === 1 ? true : false} />
                                <label className="form-check-label" for={"securityFilter" + row?.id}>
                                    {row.name}
                                </label>
                            </div>
                        </div>
                    )
                })}
            </div>
            <hr />
            <h1 className="project_filter">Device Filter</h1>
            <div className='row'>
                {deviceFilters?.map((row, i) => {
                    return (
                        <div className="col-md-3">
                            <div className="form-check mb-3">
                                <input className="form-check-input" type="checkbox" id={"deviceFilter" + row?.id} value={row?.id}
                                    checked={(row?.id === childDetail?.deviceFilters?.[i]?.id && childDetail?.deviceFilters?.[i]?.status === 1) ? true : false}
                                />
                                <label className="form-check-label" for={"deviceFilter" + row?.id}>
                                    {row?.name}
                                </label>
                            </div>
                        </div>
                    )
                })}
            </div>
            <hr />
            <h5 className="header-title"><strong>Project Field Data</strong> </h5>
            <div className="row boxShadow1">
                <div className="col-md-6 col-12">
                    <div className="row padTop">
                        <div className="col-lg-4 col-md-4 col-5 pl pr-0">
                            <span className="fontBold">EPC</span><span className="fontBold pull-right">:</span>
                        </div>
                        <div className="col-lg-8 col-md-8 col-7">
                            <span className="detail-list" id="epc">{childDetail?.epc}</span>
                        </div>
                    </div>
                    <div className="row padTop">
                        <div className="col-lg-4 col-md-4 col-5 pl pr-0">
                            <span className="fontBold">Field IR (%)</span><span className="fontBold pull-right">:</span>
                        </div>
                        <div className="col-lg-8 col-md-8 col-7">
                            <span className="detail-list" id="ddlfieldIr">{childDetail?.ir}</span>
                        </div>
                    </div>
                    <div className="row padTop">
                        <div className="col-lg-4 col-md-4 col-5 pl pr-0">
                            <span className="fontBold">Conversion Rate (%)</span><span className="fontBold pull-right">:</span>
                        </div>
                        <div className="col-lg-8 col-md-8 col-7">
                            <span className="detail-list" id="conversionRate">{childDetail?.cr}</span>
                        </div>
                    </div>
                    <div className="row padTop">
                        <div className="col-lg-4 col-md-4 col-5 pl pr-0">
                            <span className="fontBold"> Drop Rate (%)</span><span className="fontBold pull-right">:</span>
                        </div>
                        <div className="col-lg-8 col-md-8 col-7">
                            <span className="detail-list" id="dropRate">{childDetail?.dr}</span>
                        </div>
                    </div>
                    <div className="row padTop">
                        <div className="col-lg-4 col-md-4 col-5 pl pr-0">
                            <span className="fontBold">MedianLOI</span><span className="fontBold pull-right">:</span>
                        </div>
                        <div className="col-lg-8 col-md-8 col-7">
                            <span className="detail-list" id="medianLOI">{childDetail?.medianLOI}</span>
                        </div>
                    </div>

                </div>
                <div className="col-md-6 col-12">
                    <div className="row padTop">
                        <div className="col-lg-4 col-md-4 col-5 pl pr-0">
                            <span className="fontBold">Last Complete</span><span className="fontBold pull-right">:</span>
                        </div>
                        <div className="col-lg-8 col-md-8 col-7">
                            <span className="detail-list" id="lastComplete">{childDetail?.lastComplete ? (<>{moment(childDetail?.lastComplete * 1000).format("DD-MM-yyyy")}</>) : (<></>)}</span>
                        </div>
                    </div>
                    <div className="row padTop">
                        <div className="col-lg-4 col-md-4 col-5 pl pr-0">
                            <span className="fontBold"> Remaining Sample </span><span className="fontBold pull-right">:</span>
                        </div>
                        <div className="col-lg-8 col-md-8 col-7">
                            <span className="detail-list" id="ddlremainingSample">{childDetail?.remainingSample}</span>
                        </div>
                    </div>
                    <div className="row padTop">
                        <div className="col-lg-4 col-md-4 col-5 pl pr-0">
                            <span className="fontBold">Client Click</span><span className="fontBold pull-right">:</span>
                        </div>
                        <div className="col-lg-8 col-md-8 col-7">
                            <span className="detail-list" id="clientClick">{childDetail?.starts}</span>
                        </div>
                    </div>

                </div>
            </div>
            <hr />
            <h5 className="header-title"><strong>Supplier Mapped</strong> </h5>
            <div className='table-responsive'>
                <table className="table table-striped table-hover table_client ">
                    <thead>
                        <tr className="bg-primary" style={{ color: "white" }}>
                            <th scope="col">Sr.No.</th>
                            <th scope="col">SupplierCode</th>
                            <th scope="col">SupplierName</th>
                            <th scope="col">FieldIR</th>
                            <th scope="col">Conversion</th>
                            <th scope="col">DropRate</th>
                            <th scope="col">FieldLOI</th>
                            <th scope="col">Total</th>
                            <th scope="col">Complete</th>
                            <th scope="col">Terminate</th>
                            <th scope="col">OverQuota</th>
                            <th scope="col">QualityTerm</th>
                            <th scope="col">DropOut</th>
                            <th scope="col">Active</th>
                            <th scope="col">Reject</th>
                            <th scope="col">DuplicateUser</th>
                            <th scope="col">DuplicateIP</th>
                            <th scope="col">GeoIP</th>
                            <th scope="col">DeviceType</th>
                            <th scope="col">BlockedIP</th>
                            <th scope="col">Speeder</th>
                            <th scope="col">Exclude</th>
                            <th scope="col">PSStart</th>
                            <th scope="col">PSTerminate</th>
                            <th scope="col">PSOverQuota</th>
                            <th scope="col">PSDropOut</th>
                            <th scope="col">SurveyClose</th>
                            <th scope="col">Inactive</th>
                            <th scope="col">TrafficDisabled</th>
                            <th scope="col">ProjectCompleteExhausted</th>
                            <th scope="col">ProjectClickExhausted</th>
                            <th scope="col">SupplierCompleteExhausted</th>
                            <th scope="col">SupplierClickExhausted</th>
                            <th scope="col">MultiLinkNotAvailable</th>
                            <th scope="col">IdentifierNotExist(Recontact)	</th>
                            <th scope="col">FraudUser</th>
                            <th scope="col">TSFailure</th>
                            <th scope="col">RDFailure</th>
                            <th scope="col">TestLink</th>
                        </tr>
                    </thead>
                    <tbody>

                        {mappedSupplierList?.map((s, i) => {
                            tFieldIR += s?.values?.fieldIR
                            tConversion += s?.values?.conversion
                            tDropRate += s?.values?.dropRate
                            tFieldLOI += s?.values?.fieldLOI
                            tTotal += s?.values?.total
                            tComplete = tComplete + s?.completes
                            tTerminate += s?.terminates
                            tOverQuota += s?.overQuota
                            tQualityTerm += s?.qualityTeam
                            tDropOut += s?.dropRate
                            tActive += s?.values?.active
                            tReject += s?.values?.reject
                            tDuplicateUser += s?.values?.duplicateUser
                            tDuplicateIp += s?.values?.duplicateIP
                            tGeoIp += s?.values?.geoIP
                            tDeviceType += s?.values?.deviceType
                            tBlockedIP += s?.values?.blockedIP;
                            tSpeeder += s?.values?.speeder
                            tExclude += s?.values?.exclude
                            tPsStart += s?.values?.psStart;
                            tPsTerminate += s?.values?.psTerminate
                            tPsOverQuota += s?.values?.psOverQuota;
                            tPsDropOut += s?.values?.psDropOut
                            tSurveyClose += s?.values?.surveyClose;
                            tInActie += s?.values?.inactive
                            tTrafficDisabled += s?.values?.trafficDisabled
                            tProjectCompleteExhausted += s?.values?.projectCompleteExhausted;
                            tProjectClickExhausted += s?.values?.projectClickExhausted;
                            tSupplierCompleteExhausted += s?.values?.supplierCompleteExhausted;
                            tSupplierClickExhausted += s?.values?.supplierClickExhausted;
                            tMultiLinkNotAvailable += s?.values?.multiLinkNotAvailable;
                            tIdentifierNotExist += s?.values?.identifierNotExist;
                            tFroudUser += s?.values?.fraudUser;
                            tTSFailure += s?.values?.tsFailure
                            tRDFailure += s?.values?.rdFailure;
                            tTestLink += s?.values?.testLink;
                            return (<>
                                <tr>
                                    <th scope="col">{i + 1}</th>
                                    <th scope="col">{s?.supplierCode}</th>
                                    <th scope="col">{s?.supplierName}</th>
                                    <th scope="col">{s?.values?.fieldIR}</th>
                                    <th scope="col">{s?.values?.conversion}</th>
                                    <th scope="col">{s?.values?.dropRate}</th>
                                    <th scope="col">{s?.values?.fieldLOI}</th>
                                    <th scope="col">{s?.values?.total}</th>
                                    <th scope="col">{s?.completes}</th>
                                    <th scope="col">{s?.terminates}</th>
                                    <th scope="col">{s?.overQuota}</th>
                                    <th scope="col">{s?.qualityTeam}</th>
                                    <th scope="col">{s?.closed}</th>
                                    <th scope="col">{s?.values?.active}</th>
                                    <th scope="col">{s?.values?.reject}</th>
                                    <th scope="col">{s?.values?.duplicateUser}</th>
                                    <th scope="col">{s?.values?.duplicateIP}</th>
                                    <th scope="col">{s?.values?.geoIP}</th>
                                    <th scope="col">{s?.values?.deviceType}</th>
                                    <th scope="col">{s?.values?.blockedIP}</th>
                                    <th scope="col">{s?.values?.speeder}</th>
                                    <th scope="col">{s?.values?.exclude}</th>
                                    <th scope="col">{s?.values?.psStart}</th>
                                    <th scope="col">{s?.values?.psTerminate}</th>
                                    <th scope="col">{s?.values?.psOverQuota}</th>
                                    <th scope="col">{s?.values?.psDropOut}</th>
                                    <th scope="col">{s?.values?.surveyClose}</th>
                                    <th scope="col">{s?.values?.inactive}</th>
                                    <th scope="col">{s?.values?.trafficDisabled}</th>
                                    <th scope="col">{s?.values?.projectCompleteExhausted}</th>
                                    <th scope="col">{s?.values?.projectClickExhausted}</th>
                                    <th scope="col">{s?.values?.supplierCompleteExhausted}</th>
                                    <th scope="col">{s?.values?.supplierClickExhausted}</th>
                                    <th scope="col">{s?.values?.multiLinkNotAvailable}</th>
                                    <th scope="col">{s?.values?.identifierNotExist}</th>
                                    <th scope="col">{s?.values?.fraudUser}</th>
                                    <th scope="col">{s?.values?.tsFailure}</th>
                                    <th scope="col">{s?.values?.rdFailure}</th>
                                    <th scope="col">{s?.values?.testLink}</th>
                                </tr>
                            </>)
                        })}

                    </tbody>
                    <tfoot>
                        <tr className="bg-primary" style={{ color: "white" }}>
                            <th>Total</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th>{tTotal}</th>
                            <th>{tComplete}</th>
                            <th>{tTerminate}</th>
                            <th>{tOverQuota}</th>
                            <th>{tQualityTerm}</th>
                            <th>{tDropOut}</th>
                            <th>{tActive}</th>
                            <th>{tReject}</th>
                            <th>{tDuplicateUser}</th>
                            <th>{tDuplicateIp}</th>
                            <th>{tGeoIp}</th>
                            <th>{tDeviceType}</th>
                            <th>{tBlockedIP}</th>
                            <th>{tSpeeder}</th>
                            <th>{tExclude}</th>
                            <th>{tPsStart}</th>
                            <th>{tPsTerminate}</th>
                            <th>{tPsOverQuota}</th>
                            <th>{tPsDropOut}</th>
                            <th>{tSurveyClose}</th>
                            <th>{tInActie}</th>
                            <th>{tTrafficDisabled}</th>
                            <th>{tProjectCompleteExhausted}</th>
                            <th>{tProjectClickExhausted}</th>
                            <th>{tSupplierCompleteExhausted}</th>
                            <th>{tSupplierClickExhausted}</th>
                            <th>{tMultiLinkNotAvailable}</th>
                            <th>{tIdentifierNotExist}</th>
                            <th>{tFroudUser}</th>
                            <th>{tTSFailure}</th>
                            <th>{tRDFailure}</th>
                            <th>{tTestLink}</th>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <hr />
            <h5 className="header-title"><strong>Redirect Links</strong> </h5>
            <div className="row boxShadow">
                {redirectLinks?.map((rl, i) => {
                    return (<>
                        <div className="col-md-12 col-lg-12">
                            <div className="row padTop">
                                <div className="col-lg-3 col-md-4 col-5 pl pr-0">
                                    <input type="hidden" id={"Complete_status" + i} autocomplete="off" value="2997" />
                                    <span className="fontBold">{rl?.label}</span><span className="fontBold pull-right">:</span>
                                </div>
                                <div className="col-lg-9 col-md-8 col-7">
                                    <i className="ri-file-copy-line" style={{ cursor: "pointer", color: "#f04e23" }} onClick={(e) => copyRedirectLinkText(e, rl.link, i)} title="Copy text"></i><span className='redirectUrl' id={"redirectUrl" + i}>{rl.link}</span>
                                    {/* <span className="detail-list" ><i className=" ri-file-copy-line"></i> {rl.link}</span> */}
                                </div>
                            </div>

                        </div>
                    </>)
                })}

            </div>
            
            <hr></hr>
        </div>
    </div>
    </>)
}

export default TabGroupChildDetail