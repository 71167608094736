import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { analyticsSection1, analyticsSection2, analyticsTopProjects, analyticsTopSupplier, analyticsUserByDevice } from '../Api/Dashboard';
import { changeNumberFormat } from '../Common/NumberConversion';
import Footer from './Layout/Footer';
import Header from './Layout/Header';
import Sidebar from './Layout/Sidebar';
import { Chart } from "react-google-charts";
import Preloader from './Layout/Preloader';
import {
    CChart,
    CChartBar,
    CChartHorizontalBar,
    CChartLine,
    CChartDoughnut,
    CChartRadar,
    CChartPie,
    CChartPolarArea,
} from '@coreui/react-chartjs'

import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer, LineChart, Line, } from 'recharts';
const data = [{ name: 'Jan', value: '500', fill: '#1f77b4', },
{ name: 'Feb', value: '200', fill: '#1f77b4', },
{ name: 'Mar', value: '600', fill: '#1f77b4', },
{ name: 'Apr', value: '400', fill: '#1f77b4', },
{ name: 'May', value: '300', fill: '#1f77b4', },
{ name: 'Jun', value: '100', fill: '#1f77b4', },
{ name: 'Jul', value: '400', fill: '#1f77b4', },
{ name: 'Aug', value: '600', fill: '#1f77b4', },
{ name: 'Sep', value: '400', fill: '#1f77b4', },
{ name: 'Oct', value: '300', fill: '#1f77b4', },
{ name: 'Nov', value: '100', fill: '#1f77b4', },
{ name: 'Dec', value: '400', fill: '#1f77b4', },



];

const sliceColor = [
    { color: '#405189' }, { color: '#0ab39c' }, { color: '#299cdb' }, { color: '#f7b84b' }, { color: '#f06548' }
]
const options = {
    pieHole: 0.5,
    is3D: false,
    chartArea: {
        left: "3%",
        top: "3%",
        height: "94%",
        width: "94%",
        vAxis: { format: '0.00000000' }
    },
    tooltip: { isHtml: true, trigger: "visible" },
    slices: sliceColor
    // tooltip: { isHtml: true, trigger: "visible" }
};
function Dashboard() {

    useEffect(() => {
        document.title = "Dashboard - Mindforce"
    },);
    const [section1, setSection1] = useState();
    const [section2, setSection2] = useState();
    const [sectionUserByDevice, setSectionUserByDevice] = useState()
    const [sectionTopSupplier, setSectionTopSupplier] = useState()
    const [topSupplierTotalResponse, setTopSupplierTotalResponse] = useState();
    const [topSupplierIndividualResponse, setTopSupplierIndividaualResponse] = useState([])
    const [sectionTopProject, setSectionTopProject] = useState();
    const [chartDataUserByDevice, setChartDataUserByDevice] = useState()
    useEffect(() => {
        const section1 = async () => {
            let res = await analyticsSection1()
            if (res.status === 1) {
                setSection1(res.payload)
            }
        }
        section1()
    }, [])

    useEffect(() => {
        const section2 = async () => {
            let res = await analyticsSection2()
            if (res.status === 1) {
                setSection2(res.payload)
            }
        }
        section2()
    }, [])

    useEffect
        (() => {
            const sectionUserByDevice = async () => {
                let res = await analyticsUserByDevice()
                if (res.status === 1) {
                    setSectionUserByDevice(res.payload)
                    let data2 = [['name', 'value']]
                    res.payload.forEach(ele => {
                        let dataArray = [ele.deviceType, ele.value]
                        data2.push(dataArray);
                    });
                    setChartDataUserByDevice(data2)
                }
            }
            sectionUserByDevice()
        }, [])

    useEffect(() => {
        const sectionTopSupplier = async () => {
            let res = await analyticsTopSupplier()
            let individual = [];
            if (res.status === 1) {
                setSectionTopSupplier(res.payload)
                res?.payload?.details.forEach(ele => {
                    individual.push(parseInt(ele?.value) * 100 / parseInt((res.payload?.totalResponses ? res.payload?.totalResponses : 1)));
                });
                setTopSupplierTotalResponse(changeNumberFormat(res.payload?.totalResponses.toString()))
                setTopSupplierIndividaualResponse(individual)
            }
        }
        sectionTopSupplier()
        console.log(topSupplierIndividualResponse)
    }, [])

    useEffect(() => {
        const sectionTopProject = async () => {
            let res = await analyticsTopProjects()
            if (res.status === 1) {
                setSectionTopProject(res.payload)
            }
        }
        sectionTopProject()
    }, [])
    return (
        <div>
            <Header />
            <Sidebar />
            <div id="layout-wrapper">
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            {/* <!-- start page title --> */}
                            <div className="row">
                                <div className="col-12 col-lg-12 col-xl-12 col-md-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Dashboard</h4>

                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                {/* <li className="breadcrumb-item"><a >Dashboards</a></li> */}
                                                <li className="breadcrumb-item active">Dashboard</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}

                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="d-flex flex-column h-100">
                                        <div className="row h-100">
                                            <div className="col-12 col-lg-12 col-md-12 ">
                                                <div className="card">
                                                    <div className="card-body p-0">
                                                        <div className="alert alert-warning border-0 rounded-0 m-0 d-flex align-items-center" role="alert">
                                                            <i className={section1?.alert?.messageType + " me-2 icon-sm " + section1?.alert?.iconType}></i>
                                                            <div className="flex-grow-1 text-truncate">
                                                                {section1?.alert?.message}
                                                            </div>
                                                            <div className="flex-shrink-0">
                                                                <a href={section1?.link?.url} className="text-reset text-decoration-underline"><b>{section1?.link?.text} </b></a>
                                                            </div>
                                                        </div>

                                                        <div className="row align-items-end">
                                                            <div className="col-sm-8 col-lg-8">
                                                                <div className="p-3">
                                                                    <p className="fs-16 lh-base">{section1?.response?.text}
                                                                        {section1?.response?.link?.text ? (<>
                                                                            ,
                                                                            <a href={section1?.response?.link?.url}>
                                                                                {section1?.response?.link?.text} {section1?.response?.link ? (<><i className="mdi mdi-arrow-right"></i></>) : (<></>)}
                                                                            </a>
                                                                        </>) : (<></>)}

                                                                    </p>
                                                                    {section1?.button?.map((btn) => {
                                                                        return (<>
                                                                            <div className="mt-3">
                                                                                <a href={btn?.url} className={btn?.btnType}>{btn?.text}</a>
                                                                            </div>
                                                                        </>)
                                                                    })}

                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4 col-lg-4">
                                                                <div className="px-3">
                                                                    <img src={section1?.imageSrc} className="img-fluid" alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <!-- end card-body--> */}
                                                </div>
                                            </div>
                                            {/* <!-- end col--> */}
                                        </div>
                                        {/* <!-- end row--> */}

                                        <div className="row">
                                            {section2?.map((sec2, i) => {
                                                return (<>
                                                    {i > -1 && i < 5 ? (<>
                                                        <div className="col">
                                                            <div className="card card-animate">
                                                                <div className="card-body">
                                                                    <div className="d-flex justify-content-between">
                                                                        <div>
                                                                            <p className="fw-medium text-muted mb-0">{sec2.title}</p>
                                                                            <h2 className="ff-secondary fw-semibold">
                                                                                <span className="counter-value" data-target={sec2.value}>{sec2?.value}</span>
                                                                                {sec2?.value > 0 ? '' : ''}
                                                                            </h2>

                                                                        </div>
                                                                        <div>
                                                                            <div className="avatar-sm flex-shrink-0">
                                                                                <span className="avatar-title bg-soft-info rounded-circle fs-2">
                                                                                    <i data-feather="users" className={"text-info ri-" + sec2.iconType}></i>
                                                                                </span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <p className="mb-0 text-muted card_dash">
                                                                            <span className={sec2?.growthDetails?.isPositive ? "badge bg-light text-success mb-0" : "badge bg-light text-danger mb-0"}>
                                                                                <i className={sec2?.growthDetails?.isPositive ? "ri-arrow-up-line align-middle" : "ri-arrow-down-line align-middle"}></i>
                                                                                {sec2?.growthDetails?.value} {sec2?.growthDetails?.valueType === 'percentage' ? "%" : ""}
                                                                            </span>
                                                                            vs. {sec2?.growthDetails?.label}
                                                                        </p>
                                                                    </div>

                                                                    {/* <div className='tt'>
                                                                    {sec2.isExportReportAllowed ? (<>
                                                                        <button type="button" className="btn btn-soft-primary btn-sm mt-2">
                                                                            Export Report
                                                                        </button>
                                                                    </>) : (<></>)}
                                                                    
                                                                </div> */}
                                                                </div>
                                                                {/* <!-- end card body --> */}
                                                            </div>
                                                            {/* <!-- end card--> */}
                                                        </div>
                                                    </>) : (<></>)}


                                                    {/* <!-- end col--> */}
                                                </>)
                                            })}
                                        </div>
                                        <div className="row">
                                            {section2?.map((sec2, i) => {
                                                return (<>
                                                    {i > 4 && i < 10 ? (<>
                                                        <div className="col">
                                                            <div className="card card-animate">
                                                                <div className="card-body">
                                                                    <div className="d-flex justify-content-between">
                                                                        <div>
                                                                            <p className="fw-medium text-muted mb-0">{sec2.title}</p>
                                                                            <h2 className="ff-secondary fw-semibold">
                                                                                <span className="counter-value" data-target={sec2.value}>{sec2?.value}</span>
                                                                                {sec2?.value > 0 ? '' : ''}
                                                                            </h2>

                                                                        </div>
                                                                        <div>
                                                                            <div className="avatar-sm flex-shrink-0">
                                                                                <span className="avatar-title bg-soft-info rounded-circle fs-2">
                                                                                    <i data-feather="users" className={"text-info ri-" + sec2.iconType}></i>
                                                                                </span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <p className="mb-0 text-muted card_dash">
                                                                            <span className={sec2?.growthDetails?.isPositive ? "badge bg-light text-success mb-0" : "badge bg-light text-danger mb-0"}>
                                                                                <i className={sec2?.growthDetails?.isPositive ? "ri-arrow-up-line align-middle" : "ri-arrow-down-line align-middle"}></i>
                                                                                {sec2?.growthDetails?.value} {sec2?.growthDetails?.valueType === 'percentage' ? "%" : ""}
                                                                            </span>
                                                                            vs. {sec2?.growthDetails?.label}
                                                                        </p>
                                                                    </div>
                                                                    {/* <div className='tt'>
                                                                    {sec2.isExportReportAllowed ? (<>
                                                                        <button type="button" className="btn btn-soft-primary btn-sm mt-2">
                                                                            Export Report
                                                                        </button>
                                                                    </>) : (<></>)}
                                                                    
                                                                </div> */}
                                                                </div>
                                                                {/* <!-- end card body --> */}
                                                            </div>
                                                            {/* <!-- end card--> */}
                                                        </div>
                                                    </>) : (<></>)}


                                                    {/* <!-- end col--> */}
                                                </>)
                                            })}
                                        </div>
                                        <div className="row">
                                            {section2?.map((sec2, i) => {
                                                return (<>
                                                    {i > 9 && i < 15 ? (
                                                        <>
                                                            <div className="col">
                                                                <div className="card card-animate">
                                                                    <div className="card-body">
                                                                        <div className="d-flex justify-content-between">
                                                                            <div>
                                                                                <p className="fw-medium text-muted mb-0">{sec2.title}</p>
                                                                                <h2 className="ff-secondary fw-semibold">
                                                                                    <span className="counter-value" data-target={sec2.value}>{sec2?.value}</span>
                                                                                    {sec2?.value > 0 ? '' : ''}
                                                                                </h2>

                                                                            </div>
                                                                            <div>
                                                                                <div className="avatar-sm flex-shrink-0">
                                                                                    <span className="avatar-title bg-soft-info rounded-circle fs-2">
                                                                                        <i data-feather="users" className={"text-info ri-" + sec2.iconType}></i>
                                                                                    </span>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex">
                                                                            <p className="mb-0 text-muted card_dash">
                                                                                <span className={sec2?.growthDetails?.isPositive ? "badge bg-light text-success mb-0" : "badge bg-light text-danger mb-0"}>
                                                                                    <i className={sec2?.growthDetails?.isPositive ? "ri-arrow-up-line align-middle" : "ri-arrow-down-line align-middle"}></i>
                                                                                    {sec2?.growthDetails?.value} {sec2?.growthDetails?.valueType === 'percentage' ? "%" : ""}
                                                                                </span>
                                                                                vs. {sec2?.growthDetails?.label}
                                                                            </p>
                                                                        </div>
                                                                        {/* <div className='tt'>
                                                                    {sec2.isExportReportAllowed ? (<>
                                                                        <button type="button" className="btn btn-soft-primary btn-sm mt-2">
                                                                            Export Report
                                                                        </button>
                                                                    </>) : (<></>)}
                                                                    
                                                                </div> */}
                                                                    </div>
                                                                    {/* <!-- end card body --> */}
                                                                </div>
                                                                {/* <!-- end card--> */}
                                                            </div>
                                                        </>
                                                    ) : (<></>)}


                                                    {/* <!-- end col--> */}
                                                </>)
                                            })}
                                        </div>
                                        <div className="row">
                                            {section2?.map((sec2, i) => {
                                                return (<>
                                                    {i > 14 && i < 20 ? (
                                                        <>
                                                            <div className="col">
                                                                <div className="card card-animate">
                                                                    <div className="card-body">
                                                                        <div className="d-flex justify-content-between">
                                                                            <div>
                                                                                <p className="fw-medium text-muted mb-0">{sec2.title}</p>
                                                                                <h2 className="ff-secondary fw-semibold">
                                                                                    <span className="counter-value" data-target={sec2.value}>{sec2?.value}</span>
                                                                                    {sec2?.value > 0 ? '' : ''}
                                                                                </h2>

                                                                            </div>
                                                                            <div>
                                                                                <div className="avatar-sm flex-shrink-0">
                                                                                    <span className="avatar-title bg-soft-info rounded-circle fs-2">
                                                                                        <i data-feather="users" className={"text-info ri-" + sec2.iconType}></i>
                                                                                    </span>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex">
                                                                            <p className="mb-0 text-muted card_dash">
                                                                                <span className={sec2?.growthDetails?.isPositive ? "badge bg-light text-success mb-0" : "badge bg-light text-danger mb-0"}>
                                                                                    <i className={sec2?.growthDetails?.isPositive ? "ri-arrow-up-line align-middle" : "ri-arrow-down-line align-middle"}></i>
                                                                                    {sec2?.growthDetails?.value} {sec2?.growthDetails?.valueType === 'percentage' ? "%" : ""}
                                                                                </span>
                                                                                vs. {sec2?.growthDetails?.label}
                                                                            </p>
                                                                        </div>
                                                                        {/* <div className='tt'>
                                                                    {sec2.isExportReportAllowed ? (<>
                                                                        <button type="button" className="btn btn-soft-primary btn-sm mt-2">
                                                                            Export Report
                                                                        </button>
                                                                    </>) : (<></>)}
                                                                    
                                                                </div> */}
                                                                    </div>
                                                                    {/* <!-- end card body --> */}
                                                                </div>
                                                                {/* <!-- end card--> */}
                                                            </div>
                                                        </>
                                                    ) : (<></>)}


                                                    {/* <!-- end col--> */}
                                                </>)
                                            })}
                                        </div>
                                        {/* <!-- end row--> */}


                                        {/* <!-- end row--> */}
                                    </div>
                                </div>
                                {/* <!-- end col--> */}


                            </div>
                            {/* <!-- end row--> */}
                            <div class="row">
                                <div class="col-xl-12 col-lg-12">
                                    <div class="card">

                                        {/* <!-- end card header --> */}

                                        <div class="card-body">
                                            <div class="report">


                                                <div className='row mt-4'>

                                                    <div className='col-md-6'>
                                                        <button className="btn btn-primary mb-4" >Monthly Trend</button>

                                                        <CChart
                                                            type="bar"
                                                            data={{
                                                                labels: ['January', 'February', 'March', 'April', 'May', 'June'],
                                                                datasets: [
                                                                    {
                                                                        label: 'Click',
                                                                        backgroundColor: '#f87979',
                                                                        data: [40, 20, 12, 39, 10, 40, 39, 80, 40],
                                                                    },
                                                                    {
                                                                        label: 'Complete ',
                                                                        backgroundColor: "rgba(151, 187, 205, 1)",
                                                                        data: [10, 70, 20, 12, 70, 10, 20, 40, 10],
                                                                    },
                                                                ],
                                                            }}
                                                            labels="months"
                                                        />
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <button className="btn btn-primary mb-4" >Daily Trend</button>
                                                        <CChart
                                                            type="line"
                                                            data={{
                                                                labels: ["January", "February", "March", "April", "May", "June", "July","August","September","October","November","December"],
                                                                datasets: [
                                                                    {
                                                                        label: "Click",
                                                                        backgroundColor: "rgba(220, 220, 220, 0.2)",
                                                                        borderColor: "rgba(220, 220, 220, 1)",
                                                                        pointBackgroundColor: "rgba(220, 220, 220, 1)",
                                                                        pointBorderColor: "#fff",
                                                                        data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 39,]
                                                                    },
                                                                    {
                                                                        label: "Complete",
                                                                        backgroundColor: "rgba(151, 187, 205, 0.2)",
                                                                        borderColor: "rgba(151, 187, 205, 1)",
                                                                        pointBackgroundColor: "rgba(151, 187, 205, 1)",
                                                                        pointBorderColor: "#fff",
                                                                        data: [50, 12, 28, 29, 7, 25, 12, 70, 60, 20, 12, 39,]
                                                                    },
                                                                ],
                                                            }}
                                                        />


                                                    </div>
                                                </div>



                                            </div>




                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-12 col-lg-12">
                                    <div class="card">

                                        {/* <!-- end card header --> */}

                                        <div class="card-body">
                                            <div class="report">



                                                <div className='row mt-4'>
                                                    <div className='col-md-6'>
                                                        <button className="btn btn-primary mb-3" >RD Monthly Trend</button>
                                                        <CChart
                                                            type="bar"
                                                            data={{
                                                                labels: ['January', 'February', 'March', 'April', 'May', 'June'],
                                                                datasets: [
                                                                    {
                                                                        label: 'Click',
                                                                        backgroundColor: '#f87979',
                                                                        data: [40, 20, 12, 39, 10, 40, 39, 80, 40],
                                                                    },
                                                                    {
                                                                        label: 'Complete ',
                                                                        backgroundColor: "rgba(151, 187, 205, 1)",
                                                                        data: [10, 70, 20, 12, 70, 10, 20, 40, 10],
                                                                    },
                                                                ],
                                                            }}
                                                            labels="months"
                                                        />
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <button className="btn btn-primary mb-3" >RD Daily Trend</button>
                                                        <CChart
                                                            type="line"
                                                            data={{
                                                                labels: ["January", "February", "March", "April", "May", "June", "July","August","September","October","November","December"],
                                                                datasets: [
                                                                    {
                                                                        label: "Click",
                                                                        backgroundColor: "rgba(220, 220, 220, 0.2)",
                                                                        borderColor: "rgba(220, 220, 220, 1)",
                                                                        pointBackgroundColor: "rgba(220, 220, 220, 1)",
                                                                        pointBorderColor: "#fff",
                                                                        data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 39,]
                                                                    },
                                                                    {
                                                                        label: "Complete",
                                                                        backgroundColor: "rgba(151, 187, 205, 0.2)",
                                                                        borderColor: "rgba(151, 187, 205, 1)",
                                                                        pointBackgroundColor: "rgba(151, 187, 205, 1)",
                                                                        pointBorderColor: "#fff",
                                                                        data: [50, 12, 28, 29, 7, 25, 12, 70, 60, 20, 12, 39,]
                                                                    },
                                                                ],
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="row">
                                <div class="col-xl-12 col-lg-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="report">
                                                <div className='row mt-4'>
                                                    <div className='col-md-6'>
                                                        <button className="btn btn-primary" >M-Sign Monthly Trend</button>
                                                        <BarChart width={500} height={400} data={data}>
                                                            <XAxis dataKey="name" stroke="#8884d8" />
                                                            <YAxis />
                                                            <Tooltip wrapperStyle={{ width: 100, backgroundColor: '#ccc' }} />
                                                            <Legend width={100} wrapperStyle={{ top: 40, right: 20, backgroundColor: '#f5f5f5', border: '1px solid #d5d5d5', borderRadius: 3, lineHeight: '40px' }} />
                                                            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                                            <Bar dataKey="value" fill="#8884d8" barSize={30} />
                                                        </BarChart>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <button className="btn btn-primary" >M-Sign Daily Trend</button>
                                                        <LineChart
                                                            width={500}
                                                            height={350}
                                                            data={data}
                                                        >
                                                            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                                            <XAxis dataKey="name" stroke="#8884d8" />
                                                            <YAxis />
                                                            <Tooltip wrapperStyle={{ width: 100, backgroundColor: '#ccc' }} />
                                                            <Legend width={100} wrapperStyle={{ top: 40, right: 20, backgroundColor: '#f5f5f5', border: '1px solid #d5d5d5', borderRadius: 3, lineHeight: '40px' }} />
                                                        </LineChart>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            <div className="row">
                                <div className="col-xl-4">
                                    <div className="card card-height-100">
                                        <div className="card-header align-items-center d-flex">
                                            <h4 className="card-title mb-0 flex-grow-1">Users by Device</h4>
                                            <div className="flex-shrink-0">
                                                <div className="dropdown card-header-dropdown">
                                                    <a className="text-reset dropdown-btn" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <span className="text-muted fs-16"><i className="mdi mdi-dots-vertical align-middle"></i></span>
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-end">
                                                        <a className="dropdown-item" href="#">Today</a>
                                                        <a className="dropdown-item" href="#">Last Week</a>
                                                        <a className="dropdown-item" href="#">Last Month</a>
                                                        <a className="dropdown-item" href="#">Current Year</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- end card header --> */}
                                        <div className="card-body">
                                            {chartDataUserByDevice ? (<>
                                                <div className='text-center' >
                                                    <Chart
                                                        chartType="PieChart"
                                                        width="100%"
                                                        data={chartDataUserByDevice}
                                                        options={options}
                                                    />
                                                    {/* <img src="assets/images/pie1chart.jpg" className="img-fluid" alt="" /> */}
                                                </div>
                                            </>) : (<>
                                                {/* <Preloader /> */}
                                            </>)}


                                            <div className="table-responsive mt-3">
                                                <table className="table table-borderless table-sm table-centered align-middle table-nowrap mb-0">
                                                    <tbody className="border-0">
                                                        {sectionUserByDevice?.map((userByDevice, i) => {
                                                            let gDet = userByDevice?.growthDetails;
                                                            return (<>
                                                                <tr>
                                                                    <td>
                                                                        <h4 className="text-truncate fs-14 fs-medium mb-0"><i className="ri-stop-fill align-middle fs-18 me-2" style={{ color: sliceColor[i].color }}></i>{userByDevice?.deviceType}</h4>
                                                                    </td>
                                                                    <td>
                                                                        <p className="text-muted mb-0"><i data-feather="users" className="me-2 icon-sm"></i>{userByDevice?.value}</p>
                                                                    </td>
                                                                    <td className="text-end">
                                                                        <p className={gDet?.isPositive ? "text-success fw-medium fs-12 mb-0" : "text-danger fw-medium fs-12 mb-0"}>
                                                                            <i className={gDet?.isPositive ? "ri-arrow-up-s-fill fs-5 align-middle" : "ri-arrow-down-s-fill fs-5 align-middle"}></i>
                                                                            {gDet?.value}{gDet?.valueType === 'percentage' ? '%' : ''}
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                            </>)
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {/* <!-- end card body --> */}
                                    </div>
                                    {/* <!-- end card --> */}
                                </div>
                                {/* <!-- end col --> */}

                                <div className="col-xl-4 col-md-6">
                                    <div className="card card-height-100">
                                        <div className="card-header align-items-center d-flex">
                                            <h4 className="card-title mb-0 flex-grow-1">Top Suppliers</h4>
                                            <div className="flex-shrink-0">
                                                {sectionTopSupplier?.isExportReportAllowed ? (<>
                                                    <button type="button" className="btn btn-soft-primary btn-sm">
                                                        Export Report
                                                    </button>
                                                </>) : (<></>)}

                                            </div>
                                        </div>

                                        <div className="card-body">

                                            <div className="row align-items-center">
                                                <div className="col-6">
                                                    <h6 className="text-muted text-uppercase fw-semibold text-truncate fs-12 mb-3">Total responses</h6>
                                                    <h4 className="mb-0">{topSupplierTotalResponse}</h4>
                                                    <p className="mb-0 mt-2 text-muted">
                                                        <span className={sectionTopSupplier?.growthDetails?.isPositive ? "badge badge-soft-success mb-0" : "badge badge-soft-danger mb-0"}>
                                                            <i className={sectionTopSupplier?.growthDetails?.isPositive ? "ri-arrow-up-line align-middle" : "ri-arrow-down-line align-middle"}></i>
                                                            {sectionTopSupplier?.growthDetails?.value}{sectionTopSupplier?.growthDetails?.valueType === 'percentage' ? '%' : ''}
                                                        </span>
                                                        vs. {sectionTopSupplier?.growthDetails?.label}
                                                    </p>
                                                </div>
                                                {/* <!-- end col --> */}
                                                <div className="col-6">
                                                    <div className="text-center">
                                                        <img src={sectionTopSupplier?.imageSrc} className="img-fluid" alt="" />
                                                    </div>
                                                </div>
                                                {/* <!-- end col --> */}
                                            </div>
                                            {/* <!-- end row --> */}
                                            <div className="mt-3 pt-2">
                                                <div className="progress progress-lg rounded-pill">
                                                    {sectionTopSupplier?.details?.map((detail, i) => {
                                                        return (<div className="progress-bar" role="progressbar" style={{ width: topSupplierIndividualResponse[i] + '%', backgroundColor: detail?.color }} aria-valuenow={topSupplierIndividualResponse[i]} aria-valuemin="0" aria-valuemax="100"></div>)
                                                    })}

                                                    {/* <div className="progress-bar bg-info" role="progressbar" style={{ width: "18%" }} aria-valuenow="18" aria-valuemin="0" aria-valuemax="100"></div>
                                                    <div className="progress-bar bg-success" role="progressbar" style={{ width: "22%" }} aria-valuenow="22" aria-valuemin="0" aria-valuemax="100"></div>
                                                    <div className="progress-bar bg-warning" role="progressbar" style={{ width: "16%" }} aria-valuenow="16" aria-valuemin="0" aria-valuemax="100"></div>
                                                    <div className="progress-bar bg-danger" role="progressbar" style={{ width: "19%" }} aria-valuenow="19" aria-valuemin="0" aria-valuemax="100"></div> */}
                                                </div>
                                            </div>
                                            {/* <!-- end --> */}

                                            <div className="mt-3 pt-2">
                                                {sectionTopSupplier?.details?.map((detail) => {
                                                    return (<>
                                                        <div className="d-flex mb-2">
                                                            <div className="flex-grow-1">
                                                                <p className="text-truncate text-muted fs-14 mb-0">
                                                                    <i className="mdi mdi-circle align-middle me-2" style={{ color: detail?.color }}></i>{detail?.name} </p>
                                                            </div>
                                                            <div className="flex-shrink-0">
                                                                <p className="mb-0">{detail?.value}{detail?.valueType === 'percentage' ? '%' : ''}</p>
                                                            </div>
                                                        </div>
                                                        {/* <!-- end --> */}
                                                    </>)
                                                })}
                                            </div>
                                            {/* <!-- end --> */}
                                            {sectionTopSupplier?.isShowAllBtnActive ? (<>
                                                <div className="mt-2 text-center">
                                                    <a href="/supplier" className="text-muted text-decoration-underline">Show All</a>
                                                </div>
                                            </>) : (<></>)}
                                        </div>
                                        {/* <!-- end card body --> */}
                                    </div>
                                    {/* <!-- end card --> */}
                                </div>
                                {/* <!-- end col --> */}

                                <div className="col-xl-4 col-md-6">
                                    <div className="card card-height-100">
                                        <div className="card-header align-items-center d-flex">
                                            <h4 className="card-title mb-0 flex-grow-1">Top projects</h4>
                                            <div className="flex-shrink-0">
                                                <div className="dropdown card-header-dropdown">
                                                    <a className="text-reset dropdown-btn" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <span className="text-muted fs-16"><i className="mdi mdi-dots-vertical align-middle"></i></span>
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-end">
                                                        <a className="dropdown-item" href="#">Today</a>
                                                        <a className="dropdown-item" href="#">Last Week</a>
                                                        <a className="dropdown-item" href="#">Last Month</a>
                                                        <a className="dropdown-item" href="#">Current Year</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- end card header --> */}
                                        <div className="card-body">
                                            <div className="table-responsive table-card">
                                                <table className="table align-middle table-borderless table-centered table-nowrap mb-0">
                                                    <thead className="text-muted table-light">
                                                        <tr>
                                                            <th scope="col" style={{ width: " 62;" }}>Active Project</th>
                                                            <th scope="col">Clicks</th>
                                                            <th scope="col">responses</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {sectionTopProject?.map((project) => {
                                                            return (<>
                                                                <tr>
                                                                    <td>
                                                                        <a href="">{project?.name}</a>
                                                                    </td>
                                                                    <td>{project?.clicks}</td>
                                                                    <td>
                                                                        {project?.responses?.value}
                                                                        {project?.responses?.valueType === 'percentage' ? '%' : ''}
                                                                    </td>
                                                                </tr>
                                                                {/* <!-- end --> */}
                                                            </>)
                                                        })}
                                                    </tbody>
                                                    {/* <!-- end tbody --> */}
                                                </table>
                                                {/* <!-- end table --> */}
                                            </div>
                                            {/* <!-- end --> */}
                                        </div>
                                        {/* <!-- end cardbody --> */}
                                    </div>
                                    {/* <!-- end card --> */}
                                </div>
                                {/* <!-- end col --> */}
                            </div>
                            {/* <!-- end row --> */}

                        </div>
                        {/* <!-- container-fluid --> */}
                    </div>
                    {/* <!-- End Page-content --> */}
                </div>
            </div>
            <Footer />
        </div>

    )
}

export default Dashboard;