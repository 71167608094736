
import Footer from '../Layout/Footer'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import Select from "react-select";
import React from 'react'
import Multiselect from 'multiselect-react-dropdown';


import { useEffect, useState } from 'react'
import { event } from 'jquery';
import { Action } from '@remix-run/router';


function Users() {

    const [module1, setmodule1] = useState(false)
    const [textcolor, settextcolor] = useState('text-warning')
    const [textcolor_access, settextcolor_access] = useState('text-warning')
    const [textcolor_action, settextcolor_action] = useState('text-warning')
    const [accesslog, setaccesslog] = useState(false)
    const [actionlog, setactionlog] = useState(false)
    const module1click = () => {
        setmodule1(true)
        setactionlog(false)
        setaccesslog(false)
        settextcolor('text-primary')
        settextcolor_action('text-warning')
        settextcolor_access('text-warning')
    }

    const accessclick = () => {
        setaccesslog(true)
        setactionlog(false)
        setmodule1(false)
        settextcolor_access('text-primary')
        settextcolor('text-warning')
        settextcolor_action('text-warning')
    }
    const actionclick = () => {
        setactionlog(true)
        setaccesslog(false)
        setmodule1(false)
        settextcolor_access('text-warning')
        settextcolor('text-warning')
        settextcolor_action('text-primary')
    }

    useEffect(() => {

        document.title = "Users - Mindforce"



    },);
    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            <div class="row">
                                <div class="col-12">
                                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 class="mb-sm-0">Users</h4>

                                        <div class="page-title-right">
                                            <ol class="breadcrumb m-0">
                                                <li class="breadcrumb-item"><a >Admin</a></li>
                                                <li class="breadcrumb-item active">Users</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 ">
                                    <div class=" card_user mb-4">
                                        <div class=" ">
                                            <div class="d-sm-flex align-items-center justify-content-between">
                                                <div class='search'>
                                                    <input type="text" className="form-control" placeholder="Search..." autocomplete="off" id="search-options" value="" />

                                                </div>

                                                <button class="btn btn-primary d-flex " data-bs-toggle="modal" data-bs-target="#exampleModalgrid"><i className="ri-add-circle-line add_i"></i>  Add User </button>




                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row h-100">
                                <div className="col-12">
                                    <div className="card mb-4 ">
                                        <div className='ribbon text-right'>
                                    <div className="ribbon-three ribbon-three-info "><span>New</span></div></div>
                                        <div className="card-body user_sec p-4">


                                            <div className="row ">
                                                <div className="col-sm-8">
                                                    
                                                    <div className="p-2 mt-2">

                                                        <div className='d-flex align-items-center justify-content-left gap-2'>

                                                            <p className="fs-14 fw-semibold  text-primary">Email: <span >rohit@gmail.com</span></p>
                                                            <p className="fs-14  ">|</p>
                                                            <p className="fs-14 fw-semibold  text-primary">Last Login: <span>12 Dec, 2022 09:20pm</span></p>

                                                        </div>
                                                        <div className='d-flex align-items-center justify-content-left gap-2'>

                                                            <p className="fs-14 fw-semibold  text-primary">Full Name: <span >Rohit Bisht</span></p>

                                                            <p className="fs-14 fw-semibold  text-primary">Contact No: <span>8975646785</span></p>

                                                        </div>
                                                        <p className="fs-14 fw-semibold  text-primary ">Created On: <span>02 Dec, 2022 09:23am</span></p>
                                                        <p className="fs-14 fw-semibold  text-primary ">Roles: <span>Sales, Admin, Operation</span></p>

                                                        <div className='d-flex align-items-center justify-content-left gap-3 mt-4  module_user'>

                                                            <button className={`fs-14  btn-light-module fw-semibold ${textcolor}`} onClick={module1click} >Modules</button>

                                                            <button className={`fs-14  btn-light-module fw-semibold ${textcolor_access}`} onClick={accessclick}>Access Logs</button>
                                                            <button className={`fs-14  btn-light-module fw-semibold ${textcolor_action}`} onClick={actionclick}>Action Logs</button>
                                                        </div>


                                                    </div>

                                                    {module1 &&

                                                        <div className='lower_div p-3'>

                                                            <p className="fs-14 fw-semibold  text-primary">Module Name: <span >ABC</span></p>

                                                            <p className="fs-14 fw-semibold  text-primary">Role Name: <span>Operation</span></p>


                                                        </div>
                                                    }
                                                    {accesslog &&

                                                        <div className='lower_div p-3'>

                                                            <p className="fs-14 fw-semibold  text-primary">Access Logs: <span >ABC</span></p>

                                                            <p className="fs-14 fw-semibold  text-primary">Role : <span>Operation</span></p>


                                                        </div>
                                                    }
                                                    {actionlog &&

                                                        <div className='lower_div p-3'>

                                                            <p className="fs-14 fw-semibold  text-primary">Status: <span >ABC</span></p>

                                                            <p className="fs-14 fw-semibold  text-primary">Role : <span>Operation</span></p>


                                                        </div>
                                                    }
                                                </div>

                                                <div className="col-sm-4 mt-2">
                                                    <div className="px-3 text-right justify-content-end  mt-4  d-flex">

                                    
   
       

                                                    

                                                        <div class="btn-group ">
                                                             <select id="ForminputState" class="form-select" data-choices data-choices-sorting="true">
                                                                    <option value={""} disabled selected>Status</option>
                                                                    <option>Active</option>
                                                                    <option>In Active</option>
                                                                   
                                                                  
                                                                </select>
                                                        </div>



                                                    </div>
                                                </div>

                                            </div>
                                            <hr></hr>
                                        </div>
                                        {/* <!-- end card-body--> */}
                                    </div>
                                </div>
                                {/* <!-- end col--> */}

                                <nav aria-label="Page navigation example">
                                    <ul class="pagination justify-content-end">
                                        <li class="page-item">
                                            <a class="page-link" href="#" aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                                <span class="sr-only">Previous</span>
                                            </a>
                                        </li>
                                        <li class="page-item active"><a class="page-link" href="#">1</a></li>
                                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                        <li class="page-item"><a class="page-link" href="#">4</a></li>
                                        <li class="page-item"><a class="page-link" href="#">5</a></li>
                                        <li class="page-item">
                                            <a class="page-link" href="#" aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                                <span class="sr-only">Next</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>







                            {/* modal add user */}

                            <div class="modal fade" id="exampleModalgrid" tabindex="-1" aria-labelledby="exampleModalgridLabel" aria-modal="true">
                                <div class="modal-dialog modal-dialog-centered ">
                                    <div class="modal-content">
                                        <div class="modal-header bg-warning pb-3 pt-3">
                                            <h5 class="modal-title" id="exampleModalgridLabel">User Details</h5>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <form >
                                                <div class="row g-3">
                                                    <div class="col-md-6">
                                                        <div class="mb-2">
                                                            <label for="Projectdescription" class="form-label mb-0">Email </label>
                                                            <input type="text" class="form-control" placeholder="Enter email " id="Projectdescription" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="mb-2">
                                                            <label for="Projectdescription" class="form-label mb-0">User Parent </label>

                                                            <Multiselect
                                                                showArrow


                                                                options={[
                                                                    { label: 'Operation', value: 'Operation' },
                                                                    { label: 'Admin', value: 'Admin' },
                                                                    { label: 'Super Administrator', value: 'Super Administrator' },
                                                                    { label: 'Administrator', value: 'Administrator' },


                                                                ]}
                                                                displayValue="value"


                                                                placeholder=' select'


                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="mb-2">
                                                            <label for="Projectdescription" class="form-label mb-0">First Name </label>
                                                            <input type="text" class="form-control" placeholder="Enter first name " id="Projectdescription" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="mb-2">
                                                            <label for="Projectdescription" class="form-label mb-0">Last Name </label>
                                                            <input type="text" class="form-control" placeholder="Enter last name" id="Projectdescription" />
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="hstack gap-2 justify-content-end">
                                                            <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                                                            <button type="submit" class="btn btn-primary">Save</button>
                                                        </div>


                                                    </div>

                                                    
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end modal add user */}
                        </div>


                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Users