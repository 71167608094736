import React, { useEffect, useState } from "react";
import { ProjectMappedSupperDelete, ProjectMappedSupperDetail, ProjectMappedSupplierSettingUpdate, ProjectSupplierList, ProjectSupplierMappedSingle, ProjectSupplierMappedSingleUpdate } from "../../../Api/Project";
import { CCollapse, CButton, CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter } from '@coreui/react'
import { urlValidate } from '../../../Common/Regex'
import { SupplierDetail } from "../../../Api/Supplier";
import { SelectPicker } from 'rsuite';

function TabSingleSupplerMapped(props) {
    const supplierList = props?.supplierList
    const reactionTypes = props?.reactionTypes
    const detail = props?.detail
    const [mappedSupplierList, setMappedSupplierList] = useState(props?.mappedSupplierList);
    const showPreScreenFilterTab = props?.showPreScreenFilterTab
    const showSecurityFilterTab = props?.showSecurityFilterTab

    const [sComplete, setSComplete] = useState();
    const [sTerminate, setSTerminate] = useState();
    const [sOverQuota, setSOverQuota] = useState()
    const [sQualityTerm, setSQualityTerm] = useState();
    const [sSurveyClose, setSSurveyClose] = useState()
    const [errorSComplete, setErrorSComplete] = useState();
    const [errorSTerminate, setErrorSTerminate] = useState();
    const [errorSOverQuota, setErrorSOverQuota] = useState()
    const [errorSQualityTerm, setErrorSQualityTerm] = useState();
    const [errorSSurveyClose, setErrorSSurveyClose] = useState()
    const [visibleSupplirForm, setVisibleSupplirForm] = useState(false)
    const [supplier, setSupplier] = useState()
    const [supplierQuota, setSupplierQuota] = useState()
    const [sClickQuota, setSClickQuota] = useState()
    const [sCpi, setSCpi] = useState();
    const [sRedictionType, setSRedictionType] = useState()
    const [supplierDetail, setSupplierDetail] = useState()
    const [visibleSupplierDetail, setVisibleSupplierDetail] = useState(false)
    const [supplierSingleFormButton, setSupplierSingleFormButton] = useState('Submit')
    const [msg, setMsg] = useState()
    const [optionData, setOptionData] = useState()

    const getSupplierdata = () => {

        const dataitems = [];

        supplierList.forEach(lang => {
            dataitems.push({
                label: `${lang.supplierCode}-${lang.supplierName}`, value: lang.supplierID
            })
        });
        setOptionData(dataitems)

    }
    const handleValueChangeDropdown = (value) => {
        setSupplier(value);
    };
    
    useEffect(() => {
        getProjectSupplierList()

    }, [])

    const getProjectSupplierList = async () => {
        let res = await ProjectSupplierList({ projectId: detail?.projectDetailID })
        if (res.status === 1) {
            await setMappedSupplierList(res.payload)
        } else {
            setMsg(res?.message)
        }
    }

    const getSupplierDetail = async (supplierId) => {
        let res = await SupplierDetail({ payload: supplierId })
        if (res.status === 1) {
            setSupplierDetail(res.payload[0])
            setVisibleSupplierDetail(true)
        }
    }

    const handleAddSupplierCollapse = () => {
        setSupplier()
        setSupplierQuota()
        setSClickQuota()
        setSCpi()
        setSRedictionType()
        setSupplierSingleFormButton('Submit')
        setVisibleSupplirForm(false)
        setSComplete()
        setSTerminate()
        setSOverQuota()
        setSQualityTerm()
        setSSurveyClose()
        if(document.getElementsByClassName('rs-picker-toggle-placeholder')[0])
        document.getElementsByClassName('rs-picker-toggle-placeholder')[0].innerText = "Supplier"
        document.getElementById('supplierQuota').value = '';
        document.getElementById('supplier').value = '';
        document.getElementById('clickQuota').value = '';
        document.getElementById('cpi').value = '';
        document.getElementById('redirectionType').value = '';
        setVisibleSupplirForm(!visibleSupplirForm)
    }

    const addSupplierSingle = async () => {
        setErrorSComplete();
        setErrorSTerminate()
        setErrorSOverQuota()
        setErrorSQualityTerm()
        setErrorSSurveyClose()

        let error = false;
        let payload = {
            supplierID: parseInt(supplier),
            supplierQuota: parseInt(supplierQuota),
            clickQuota: parseInt(sClickQuota),
            cpi: parseInt(sCpi),
            redirectionTypeID: parseInt(sRedictionType)
        }
        if (parseInt(sRedictionType) === 438) {
            let sCompleteUrl = urlValidate(sComplete);
            let sTerminateUrl = urlValidate(sTerminate);
            let sOverQuotaUrl = urlValidate(sOverQuota);
            let sQualityTermUrl = urlValidate(sQualityTerm);
            let sSurveyCloseUrl = urlValidate(sSurveyClose);

            if (!sComplete) {
                setErrorSComplete("Required")
                error = true;
            } else {
                if (sCompleteUrl === false) {
                    setErrorSComplete("Invalid Format")
                    error = true;
                } else {
                    payload.completeURL = sComplete
                }
            }
            if (!sTerminate) {
                setErrorSTerminate("Required")
                error = true;
            } else {
                if (sTerminateUrl === false) {
                    setErrorSTerminate("Invalid Url")
                    error = true;
                } else {
                    payload.terminateURL = sTerminate
                }

            }
            if (!sOverQuota) {
                setErrorSOverQuota("Required")
                error = true;
            } else {
                if (sOverQuotaUrl === false) {
                    setErrorSOverQuota("Invalid Url")
                    error = true;
                } else {
                    payload.overQuotaURL = sOverQuota
                }

            }
            if (!sQualityTerm) {
                setErrorSQualityTerm("Required")
                error = true;
            } else {
                if (sQualityTermUrl === false) {
                    setErrorSQualityTerm("Invalid Url")
                    error = true;
                } else {
                    payload.qualityTeamURL = sQualityTerm
                }
            }
            if (!sSurveyClose) {
                setErrorSSurveyClose("Required")
                error = true;
            } else {
                if (sSurveyCloseUrl === false) {
                    setErrorSSurveyClose("Invalid Url")
                    error = true;
                } else {
                    payload.surveyClosedURL = sSurveyClose
                }
            }
        }
        if (error) {
            return false;
        }
        let res = await ProjectSupplierMappedSingle({ payload: payload, projectDetailID: detail?.projectDetailID })
        if (res.status === 1) {
            props?.getProjectSupplierList()
            getProjectSupplierList()
            resetAddSupplier()
            setVisibleSupplirForm(false)
        }
    }

    const editSupplier = async (supplierId, projecctDetailId) => {
        let res = await ProjectMappedSupperDetail({ supplierId: supplierId, projecctDetailId: projecctDetailId })
        if (res.status === 1) {
            let data = res.payload[0]
            setSupplierDetail(res.payload[0])
            setSupplier(supplierId)
            setSupplierQuota(data?.quota)
            setSClickQuota(data?.clickQuota)
            setSCpi(data?.cpi)
            setSRedictionType(data?.redirectionTypeID)
            setSupplierSingleFormButton('Update')
            setVisibleSupplirForm(true)
            if(document.getElementsByClassName('rs-picker-toggle-placeholder')[0])
            document.getElementsByClassName('rs-picker-toggle-placeholder')[0].innerText = data?.supplierCode+'-'+data?.supplierName
            setSComplete(data?.completeURL != 'null' ? data?.completeURL : '')
            setSTerminate(data?.terminateURL != 'null' ? data?.terminateURL : '')
            setSOverQuota(data?.overQuotaURL != 'null' ? data?.overQuotaURL : '')
            setSQualityTerm(data?.qualityTeamURL != 'null' ? data?.qualityTeamURL : '')
            setSSurveyClose(data?.surveyClosedURL != 'null' ? data?.surveyClosedURL : '')
        }
    }

    const updatemappedSupplier = async () => {
        setErrorSComplete();
        setErrorSTerminate()
        setErrorSOverQuota()
        setErrorSQualityTerm()
        setErrorSSurveyClose()
        let error = false;
        let payload = {
            supplierQuota: parseInt(supplierQuota),
            clickQuota: parseInt(sClickQuota),
            cpi: parseInt(sCpi),
            redirectionTypeID: parseInt(sRedictionType),
            postbackURL: null
        }

        if (parseInt(sRedictionType) === 438) {
            let sCompleteUrl = urlValidate(sComplete);
            let sTerminateUrl = urlValidate(sTerminate);
            let sOverQuotaUrl = urlValidate(sOverQuota);
            let sQualityTermUrl = urlValidate(sQualityTerm);
            let sSurveyCloseUrl = urlValidate(sSurveyClose);

            if (!sComplete) {
                setErrorSComplete("Required")
                error = true;
            } else {
                if (sCompleteUrl === false) {
                    setErrorSComplete("Invalid Format")
                    error = true;
                } else {
                    payload.completeURL = sComplete
                }
            }
            if (!sTerminate) {
                setErrorSTerminate("Required")
                error = true;
            } else {
                if (sTerminateUrl === false) {
                    setErrorSTerminate("Invalid Url")
                    error = true;
                } else {
                    payload.terminateURL = sTerminate
                }

            }
            if (!sOverQuota) {
                setErrorSOverQuota("Required")
                error = true;
            } else {
                if (sOverQuotaUrl === false) {
                    setErrorSOverQuota("Invalid Url")
                    error = true;
                } else {
                    payload.overQuotaURL = sOverQuota
                }

            }
            if (!sQualityTerm) {
                setErrorSQualityTerm("Required")
                error = true;
            } else {
                if (sQualityTermUrl === false) {
                    setErrorSQualityTerm("Invalid Url")
                    error = true;
                } else {
                    payload.qualityTeamURL = sQualityTerm
                }
            }
            if (!sSurveyClose) {
                setErrorSSurveyClose("Required")
                error = true;
            } else {
                if (sSurveyCloseUrl === false) {
                    setErrorSSurveyClose("Invalid Url")
                    error = true;
                } else {
                    payload.surveyClosedURL = sSurveyClose
                }
            }
        }
        if (error) {
            return false;
        }
        let res = await ProjectSupplierMappedSingleUpdate({ payload: payload, projectDetailID: supplierDetail?.projectDetailID, supplierDetailId: supplier })
        if (res.status === 1) {
            getProjectSupplierList()
            setSupplierSingleFormButton('Submit')
            setVisibleSupplirForm(false)
        }
    }

    const deleteSupplierRow = async (supplierId, projecctDetailId) => {
        let res = await ProjectMappedSupperDelete({ supplierId: supplierId, projecctDetailId: projecctDetailId })
        if (res.status === 1) {
            getProjectSupplierList()
        }
    }

    const resetAddSupplier = async () => {

        setSupplier()
        setSupplierQuota()
        setSClickQuota()
        setSCpi()
        setSRedictionType()
        setSupplierSingleFormButton('Submit')
        setVisibleSupplirForm(false)
        setSComplete()
        setSTerminate()
        setSOverQuota()
        setSQualityTerm()
        setSSurveyClose()
    }

    const copyText = (e, url, index) => {
        navigator.clipboard.writeText(url);
        var supplierUrlBG = document.getElementsByClassName("supplierUrl");
        var len = supplierUrlBG.length;

        for (var i = 0; i < len; i++) {
            if (i === index) {
                supplierUrlBG[i].style.backgroundColor = "#dfdff8";
            } else {
                supplierUrlBG[i].style.backgroundColor = "transparent";
            }
        }
    }

    const updateSetting = async (e, type, supplierId) => {
        let { checked } = e.target
        let payload = {
            settingType: type,
            settingValue: checked ? 1 : 0
        }
        let res = await ProjectMappedSupplierSettingUpdate({ supplierId: supplierId, projectDetailId: detail?.projectDetailID, payload: payload })
        if (res?.status === 1) {
            getProjectSupplierList()
        } else {
            document.getElementById(type + supplierId).checked = !checked
        }

    }
    return (<>
        <div className="card-body">
            <div>
                <div className='table-responsive'>
                    <table className="table table-striped table-hover table_client ">
                        <thead>
                            <tr className="bg-primary" style={{ color: "white" }}>
                                <th scope="col">Sr.No.</th>
                                <th scope="col">SupplierCode</th>
                                <th scope="col">SupplierName</th>
                                <th scope="col">Quota</th>
                                <th scope="col">ClickQuota</th>
                                <th scope="col">CPI</th>
                                <th scope="col">SupplierProjectId</th>
                                <th scope="col">SupplierUrl</th>
                                <th scope="col">TestLink</th>
                                <th scope="col">AllowTraffic</th>
                                {showPreScreenFilterTab ? (<>
                                    <th>PreScreen</th>
                                </>) : (<></>)}

                                <th scope="col">Security</th>
                                <th scope="col">API</th>
                                <th scope="col">Status	</th>
                                <th scope="col">Action	</th>
                            </tr>
                        </thead>
                        <tbody>
                            {mappedSupplierList?.length > 0 ? (<>
                                {mappedSupplierList?.map((row, i) => {
                                    return (<>
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>
                                                <span className="clickableText" onClick={() => getSupplierDetail(row.supplierID)}>
                                                    {row.supplierCode}
                                                </span>
                                            </td>
                                            <td>{row.supplierName}</td>
                                            <td>{row.quota}</td>
                                            <td>{row.clickQuota}</td>
                                            <td>{row.cpi}</td>
                                            <td></td>
                                            <td style={{ "textAlign": 'left' }}>
                                                <i className="ri-file-copy-line" style={{ cursor: "pointer", color: "#f04e23" }} onClick={(e) => copyText(e, row.supplierUrl, i)} title="Copy text"></i><span className='supplierUrl' id={"supplierUrl" + i}>{row.supplierUrl}</span>
                                            </td>
                                            <td><input class="form-check-input" id={"testLink" + row.supplierID} type="checkbox" checked={row?.testLink === 1 ? true : false} onChange={(e) => updateSetting(e, 'testLink', row.supplierID)} /></td>
                                            <td><input class="form-check-input" id={"allowTraffic" + row.supplierID} type="checkbox" defaultChecked={row?.allowTraffic === 1 ? true : false} onChange={(e) => updateSetting(e, 'allowTraffic', row.supplierID)} /></td>
                                            {showPreScreenFilterTab ? (<>
                                                <td><input class="form-check-input" id={"prescreen" + row.supplierID} type="checkbox" defaultChecked={row?.prescreen === 1 ? true : false} onChange={(e) => updateSetting(e, 'prescreen', row.supplierID)} /></td>
                                            </>) : (<></>)}

                                            <td><input class="form-check-input" id={"security" + row.supplierID} type="checkbox" disabled={!showSecurityFilterTab} defaultChecked={row?.security === 1 ? true : false} onChange={(e) => updateSetting(e, 'security', row.supplierID)} /></td>
                                            <td><input class="form-check-input" type="checkbox" disabled={true} checked={row?.api === 1 ? true : false} /></td>
                                            <td></td>
                                            <td>
                                                <span className="p-1"><i className="ri-edit-2-line pointerC" onClick={() => editSupplier(row.supplierID, row.projectDetailID)}></i></span>
                                                {/* <span className="p-1"><i className="ri-delete-bin-2-line del pointerC" onClick={() => deleteSupplierRow(row.supplierID, row?.projectDetailID)}></i></span> */}
                                            </td>
                                        </tr>
                                    </>)
                                })}

                            </>) : (<>
                                <tr>
                                    <td colSpan={13}>
                                        <center>{msg}</center>
                                    </td>
                                </tr>
                            </>)}

                        </tbody>
                    </table>
                </div>
            </div>
            <div>
                {mappedSupplierList?.length > 0 ? (<>
                    <h5 className="clickableText mb-3 mt-5" onClick={() => { handleAddSupplierCollapse(); getSupplierdata() }} aria-expanded={visibleSupplirForm} aria-controls="supplierForm">+ Add Supplier</h5>
                </>) : (<></>)}
                
                <div>
                    <CCollapse visible={visibleSupplirForm}>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="">
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="mb-3">
                                                            <label className="form-label mb-0">Supplier <span className="required_field">*</span></label>
                                                        </div>
                                                        <div className="mb-3" id="supplier">
                                                            <SelectPicker placeholder="Select Supplier" data={optionData}
                                                                id="supplier"
                                                                defaultValue={supplier}
                                                                onChange={handleValueChangeDropdown}
                                                                disabled={supplierSingleFormButton === 'Update' ? (true) : (false)}
                                                                block />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="mb-3">
                                                            <label className="form-label mb-0">Supplier Quota <span className="required_field">*</span></label>
                                                            <input type="text" id="supplierQuota" className="form-control" placeholder="0" onChange={(e) => setSupplierQuota(e.target.value)} value={supplierQuota} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="mb-3">
                                                            <label className="form-label mb-0">Click Quota <span className="required_field">*</span></label>
                                                            <input type="text" id="clickQuota" className="form-control" placeholder="0 " value={sClickQuota} onChange={(e) => setSClickQuota(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="mb-3">
                                                            <label className="form-label mb-0">CPI <span className="required_field">*</span></label>
                                                            <input type="text" id="cpi" className="form-control" placeholder="0" value={sCpi} onChange={(e) => setSCpi(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="mb-3">
                                                            <label className="form-label mb-0">Redirection Type <span className="required_field">*</span></label>
                                                            <select className="form-select" id="redirectionType" onChange={(e) => setSRedictionType(e.target.value)}>
                                                                <option value={""} selected={true}>Select Redirection Type</option>
                                                                {reactionTypes?.map((rTypes) => {
                                                                    return (<option value={rTypes.id} selected={rTypes.id === sRedictionType ? true : false}>{rTypes.name}</option>)
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {sRedictionType?.toString() === '438' ? (<>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <table>
                                                                <tr className='mt-3'>
                                                                    <td>
                                                                        <label className="form-label mb-0">Complete <span className="required_field">*</span></label>
                                                                    </td>
                                                                    <td><input type="url" className="form-control" onChange={(e) => setSComplete(e.target.value)} value={sComplete} />
                                                                        Link eg. https://insightsenabled.mindforceresearch.in/Thanks/Verify?status=c&identifier=[IDENTIFIER]
                                                                        <div className="required_field"> {errorSComplete}</div>
                                                                    </td>
                                                                </tr>
                                                                <br />
                                                                <tr className='mt-3'>
                                                                    <td className='mt-3'>
                                                                        <label className="form-label mb-0">Terminate <span className="required_field">*</span></label>
                                                                    </td>
                                                                    <td className='mt-3'><input type="url" className="form-control" onChange={(e) => setSTerminate(e.target.value)} value={sTerminate} />
                                                                        Link eg. https://insightsenabled.mindforceresearch.in/Thanks/Verify?status=t&identifier=[IDENTIFIER]
                                                                        <div className="required_field"> {errorSTerminate}</div>
                                                                    </td>
                                                                </tr>
                                                                <br />
                                                                <tr className='mt-3'>
                                                                    <td className='mt-3'>
                                                                        <label className="form-label mb-0">Over Quota <span className="required_field">* </span></label>
                                                                    </td>
                                                                    <td className='mt-3'><input type="url" className="form-control" onChange={(e) => setSOverQuota(e.target.value)} value={sOverQuota} />
                                                                        Link eg. https://insightsenabled.mindforceresearch.in/Thanks/Verify?status=q&identifier=[IDENTIFIER]
                                                                        <div className="required_field"> {errorSOverQuota}</div>
                                                                    </td>
                                                                </tr>
                                                                <br />
                                                                <tr className='mt-3'>
                                                                    <td className='mt-3'>
                                                                        <label className="form-label mb-0">Quality Term <span className="required_field">* </span></label>
                                                                    </td>
                                                                    <td className='mt-3'><input type="url" className="form-control" onChange={(e) => setSQualityTerm(e.target.value)} value={sQualityTerm} />
                                                                        Link eg. https://insightsenabled.mindforceresearch.in/Thanks/Verify?status=false&identifier=[IDENTIFIER]
                                                                        <div className="required_field"> {errorSQualityTerm}</div>
                                                                    </td>
                                                                </tr>
                                                                <br />
                                                                <tr className='mt-3'>
                                                                    <td className='mt-3'>
                                                                        <label className="form-label mb-0">Survey Close <span className="required_field">*</span></label>
                                                                    </td>
                                                                    <td className='mt-3'><input type="url" className="form-control" onChange={(e) => setSSurveyClose(e.target.value)} value={sSurveyClose} />
                                                                        Link eg. https://insightsenabled.mindforceresearch.in/Thanks/Verify?status=sc&identifier=[IDENTIFIER]
                                                                        <div className="required_field"> {errorSSurveyClose}</div>
                                                                    </td>
                                                                </tr>
                                                                <br />
                                                            </table>
                                                        </div>
                                                    </div>
                                                </>) : (<></>)}

                                                <div className="row">
                                                    <div className='col-md-9'></div>
                                                    <div className='col-md-3'>

                                                        <div className='mb-3'>
                                                            {supplierSingleFormButton === 'Submit' ? (<>
                                                                <label className="btn btn-primary" for="Submit" onClick={() => addSupplierSingle()}>{supplierSingleFormButton}</label>
                                                                &nbsp;
                                                            </>) : (<>
                                                                <label className="btn btn-primary" for="Submit" onClick={() => updatemappedSupplier()}>{supplierSingleFormButton}</label>
                                                                &nbsp;
                                                            </>)}

                                                            <label className="btn btn-secondary" for="Cancel" onClick={() => resetAddSupplier()}>Cancel</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CCollapse>
                </div>
            </div>
        </div>

        {visibleSupplierDetail ? (<>
            <>
                {/* <CButton onClick={() => setVisibleDetail(!visibleDetail)}>Launch static backdrop modal</CButton> */}
                <CModal visible={visibleSupplierDetail} backdrop={"static"} size={"xl"} onClose={() => setVisibleSupplierDetail(false)}>
                    <CModalHeader style={{ backgroundColor: "#005499", paddingBottom: "10px", margin: "-1px" }} closeButton={false}>
                        <CModalTitle style={{ color: "#fff", width: "100%" }}>
                            Supplier Details
                            <span style={{ float: "right", cursor: "pointer" }} onClick={() => setVisibleSupplierDetail(false)}> X </span>
                        </CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                        <div className="row mt-2">
                            <div className="col-xl-6 col-lg-6">
                                <table className="GroupProjectDetailTable">
                                    <tr>
                                        <th>Supplier Code <span className="colonSeperator">:</span></th>
                                        <td>{supplierDetail?.code}</td>
                                    </tr>
                                    <tr>
                                        <th>Supplier Name  <span className="colonSeperator">:</span></th>
                                        <td>{supplierDetail?.supplierName}</td>
                                    </tr>
                                    <tr>
                                        <th>Country  <span className="colonSeperator">:</span> </th>
                                        <td>{supplierDetail?.countryName}</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="col-xl-6 col-lg-6">
                                <table className="GroupProjectDetailTable">
                                    <tr>
                                        <th>Contact Number <span className="colonSeperator">:</span></th>
                                        <td>{supplierDetail?.contactNumber}</td>
                                    </tr>
                                    <tr>
                                        <th>Email ID  <span className="colonSeperator">:</span></th>
                                        <td>{supplierDetail?.email}</td>
                                    </tr>
                                    <tr>
                                        <th>Website  <span className="colonSeperator">:</span> </th>
                                        <td>{supplierDetail?.website}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div className="form_divide"><hr ></hr></div>
                        <div className="row mt-2">
                            <div className="col-xl-12 col-lg-12">
                                <h3>Redirect Link for Client</h3>
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                <table className="GroupProjectDetailTable">
                                    <tr>
                                        <th>Complete  <span className="colonSeperator">:</span> </th>
                                        <td>{supplierDetail?.completeURL}</td>
                                    </tr>
                                    <tr>
                                        <th>Terminate  <span className="colonSeperator">:</span> </th>
                                        <td>{supplierDetail?.terminateURL}</td>
                                    </tr>
                                    <tr>
                                        <th>Over Quota  <span className="colonSeperator">:</span> </th>
                                        <td>{supplierDetail?.overQuotaURL}</td>
                                    </tr>
                                    <tr>
                                        <th>Quality Term  <span className="colonSeperator">:</span> </th>
                                        <td>{supplierDetail?.qualityTeamURL}</td>
                                    </tr>
                                    <tr>
                                        <th>Survey Close  <span className="colonSeperator">:</span> </th>
                                        <td>{supplierDetail?.surveyCloseURL}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div className="form_divide"><hr ></hr></div>
                        <div className="row mt-2">
                            <div className="col-xl-12 col-lg-12">

                                <div className="notesWrapper">
                                    <h3>Notes</h3>
                                </div>
                            </div>
                            <div className="col-xl-12 col-lg-12">

                            </div>
                        </div>
                    </CModalBody>
                </CModal>
            </>
        </>) : (<></>)}
    </>)
}

export default TabSingleSupplerMapped