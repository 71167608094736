import React from 'react'
import Footer from '../Layout/Footer'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import { useEffect } from 'react'

function Recontact() {
    useEffect(() => {

        document.title = "Re-Contact - Mindforce"



    },);
    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            <div class="row">
                                <div class="col-12">
                                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 class="mb-sm-0">ReContact</h4>

                                        <div class="page-title-right">
                                            <ol class="breadcrumb m-0">
                                                <li class="breadcrumb-item"><a >Project</a></li>
                                                <li class="breadcrumb-item active">ReContact</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}

                            <div class="row">
                                <div class="col-xl-12 col-lg-12">
                                    <div class="card">

                                        {/* <!-- end card header --> */}

                                        <div class="card-body">
                                            <div class="report">
                                                <form >
                                                    <div class="row">
                                                        <div class="col-md-6 col-lg-6">
                                                            <div class="mb-3 ">
                                                                <label for="ProjectManager" class="form-label mb-0">Search Project <span class="required_field">*</span> </label>
                                                                <div class='d-flex'>
                                                                    <input type="search" class="form-control" placeholder="" id="ProjectManager" />
                                                                    <button type="submit" class="btn btn-primary mx-2 ">Search</button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                       </div>
                                                       
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                {/* <!-- end col --> */}









                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Recontact