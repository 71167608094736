import React, { useEffect, useState } from "react";
import { CButton, CModalHeader, CModalTitle, CModalBody, CModalFooter } from '@coreui/react'
import { postAPI } from "../../../ApiService";
import Swal from "sweetalert2";
import { masterData } from "../../../Api/Master";

function PreScreenTempQuestion(props) {
    const [countryId, setCountryId] = useState(props?.detail?.countryID)
    const [languageId, setLanguageId] = useState(props?.detail?.languageID)
    const [title, setTitle] = useState();
    const [titleError, setTitleError] = useState()
    const [controlType, setControlType] = useState()
    const [controlTypeId, setControlTypeId] = useState()
    const [question, setQuestion] = useState()
    const [showOption, setShowOption] = useState(false)
    const [addOption, setAddOption] = useState()
    const [mappedOption, setMappedOption] = useState([])
    const [submitBtnTxt, setSubmitBtnTxt] = useState('Submit')
    const [errors, setErrors] = useState({});
    const [preScreenerQuestionID, setPreScreenerQuestionID] = useState();
    const [editFormTitle, setEditFormTitle] = useState()
    const [queId, setQueId] = useState()

    useEffect(() => {
        getControlType()
        if(props?.preScreenerQuestionID){
            getTempQueDetail()
        }
    },[])

    const getControlType = async () => {
        let res = await masterData({ moduleName: 'control-type' })
        if (res?.status === 1) {
            setControlType(res.payload)
        }
    }

    const getTempQueDetail = async()=>{
        let payload = {
            preScreenerQuestionID: props?.preScreenerQuestionID,
            projectDetailID: props?.detail?.projectDetailID,
        }
        let res = await postAPI('/project/prescreen/get-temp-question-detail/',payload)
        if(res?.status === 1){
            getControlType()
            let d = res?.payload?.[0]
            let mOpt = [];
            
            d?.mappedOptions.forEach(ele => {
                mOpt.push(ele.option)
            });
            setPreScreenerQuestionID(props?.preScreenerQuestionID)
            setControlTypeId(d?.controlTypeID)
            setTitle(d?.title)
            setQuestion(d?.question)
            setAddOption()
            await setMappedOption(mOpt)
            setSubmitBtnTxt('Update')

            if (d?.controlTypeID === '' || d?.controlTypeID === null || d?.controlTypeID === '433' || d?.controlTypeID === 433) {
                setShowOption(false)
            }else{
                setShowOption(true)
            }
            // props?.visible()
        }
    }

    const cancel = () => {
        props?.visible()
    }

    const handleQueTitle = async (e) => {
        if(!/[0-9a-zA-Z_]/.test(e.key)){
            e.preventDefault();
        }
    }

    const checkUnique = async () => {
        setTitleError()
        if (languageId && title && title !== editFormTitle) {
            let data = {
                projectDetailID: parseInt(props?.detail?.projectDetailID),
                title: title
            }
            let res = await postAPI('/project/prescreen/check-unique-question-title', data)
            if (res?.status === 0) {
                setTitleError(res?.message)
            }
        }
    }

    const handleControlType = (value) => {
        setShowOption(true)
        if (value === '' || value === null || value === '433' || value === 433) {
            setShowOption(false)
        }
        setControlTypeId(value)
    }

    const handleAddOption = (e) => {
        setAddOption(e.target.value)
    }

    const removeMapped = (item) => {
        let mappedList = mappedOption.filter(maped => maped != item);
        setMappedOption(mappedList)
    }

    const moveToMappedOption = async () => {
        addOption?.split('\n').map(function (item, i) {
            if (item !== '' || item !== null) {
                if (mappedOption?.indexOf(item) === -1) {
                    setMappedOption((mappedOption) => [...mappedOption, item])
                }
            }

        })
        await setAddOption("")
    }

    const handlesubmit = async (e) => {
        setErrors()
        let formData = {
            mappedOptions: mappedOption,
            controlTypeID: controlTypeId,
            title: title,
            question: question,
            description: null,
            projectDetailID: props?.detail?.projectDetailID,
        };
        let res = await postAPI('/project/prescreen/add-temp-question', formData)
        
        if (res.status === 1) {
            setControlTypeId()
            setTitle()
            document.getElementById('title').value = ""
            setQuestion("")
            document.getElementById('question').value = ""
            setAddOption()
            setMappedOption([])
            // getList();
            props?.visible()
            // linkTempQue(res?.payload?.ID)
        } else {
            setErrors({ ...errors, ['submit']: res.message })
        }
        Swal.fire({
            text:res.message,
            timer: 1500,
        })
    }

    const handleUpdate = async (e) => {
        // e.preventDefault();
        setErrors()
        let ops = []
        mappedOption.forEach(ele => {
            if(ele !== null){
                ops.push(ele)
            }
            
        });
        let formData = {
            mappedOptions: ops,
            controlTypeID: controlTypeId,
            title: title,
            question: question,
            description: null,
            preScreenerQuestionID: preScreenerQuestionID,
            projectDetailID: props?.detail?.projectDetailID,
        };
        // const formErrors = validateFormData(formData)
        let res = await postAPI('/project/prescreen/update-temp-question', formData)
        if (res.status === 1) {
            setQueId()
            setControlTypeId()
            setTitle()
            setQuestion("")
            setAddOption()
            setMappedOption([])
            setSubmitBtnTxt('Submit')
            props?.visible()
        } else {
            setErrors({ ...errors, ['submit']: res.message })
        }
        Swal.fire({
            text:res.message,
            timer: 1500,
        })
    }

    
    return (<>
        <CModalHeader>
            <CModalTitle>Add Question</CModalTitle>
        </CModalHeader>
        <CModalBody>
            <div class="row g-3">
                <div class="col-md-6">
                    <div class="mb-2">
                        <label for="Projectdescription" class="form-label mb-0">Title<span class="required_field">*{titleError}</span> </label>
                        {/* <input type="text" class="form-control" placeholder=" " id="Projectdescription" /> */}
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Enter title"
                            id="title"
                            disabled={countryId && languageId ? false : true}
                            defaultValue={title}
                            onChange={(e) => setTitle(e.target.value)}
                            onKeyDown={(e) => handleQueTitle(e)}
                            onBlur={() => checkUnique()} />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="mb-2">
                        <label for="ForminputState" class="form-label mb-0">Control Type<span class="required_field">*</span></label>
                        <select id="ForminputState" class="form-select" data-choices data-choices-sorting="true"
                            disabled={countryId && languageId ? false : true}
                            onChange={(e) => handleControlType(e.target.value)}
                        >
                            <option value={""}>Select Control</option>
                            {controlType?.map((row) => {
                                return (<option value={row.id} selected={row.id === controlTypeId ? true : false}>{row.name}</option>)
                            })}
                        </select>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="mb-2">
                        <label for="Projectdescription" class="form-label mb-0">Question<span class="required_field">*</span> </label>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Enter Question"
                            id="question"
                            defaultValue={question}
                            disabled={countryId && languageId ? false : true}
                            onChange={(e) => setQuestion(e.target.value)} />
                    </div>
                </div>
                {showOption ? (<>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="addOption" class="form-label mb-0">Add Option </label>
                            <textarea class="form-control" id="addOption" onChange={(e) => handleAddOption(e)} value={addOption} rows="3" ></textarea>

                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label class="form-label mb-0">Mapped Options</label>

                            <div style={{ height: "80px", overflowY: "scroll", border: "1px solid", padding: "0.5rem 0.9rem", borderColor: "#ced4da", borderRadius: "4px" }}>
                                {mappedOption?.map((item) => {
                                    return (<><div style={{ cursor: "pointer" }} onDoubleClick={() => removeMapped(item)}>{item}</div></>)
                                })}
                            </div>

                            <span class="max_char">Please double click any option to remove from the list</span>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="text-center mb-3">
                            <span class="btn add_qustion " style={{ backgroundColor: "gray", color: "white", cursor: "pointer" }} onClick={() => moveToMappedOption()}>Add</span>
                        </div>
                    </div>
                </>) : (<></>)}
            </div>

        </CModalBody>
        <CModalFooter>
            <CButton color="secondary" onClick={() => cancel(false)}>Close</CButton>
            {submitBtnTxt === 'Update' ? (
                <button type="submit" class="btn btn-primary mx-2" onClick={handleUpdate}>{submitBtnTxt}</button>
            ) : (
                <button type="submit" class="btn btn-primary mx-2" onClick={handlesubmit}>{submitBtnTxt}</button>
            )}
        </CModalFooter>
            
    </>)
}
export default PreScreenTempQuestion