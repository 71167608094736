import React from 'react'
import Footer from '../Layout/Footer'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import { useEffect } from 'react'

function Reconciliation() {
    useEffect(() => {

        document.title = "Reconciliation - Mindforce"



    },);
    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            <div class="row">
                                <div class="col-12">
                                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 class="mb-sm-0">Reconciliation</h4>

                                        <div class="page-title-right">
                                            <ol class="breadcrumb m-0">
                                                <li class="breadcrumb-item"><a>Support</a></li>
                                                <li class="breadcrumb-item active">Reconciliation</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}

                            <div class="row">
                                <div class="col-xl-12 col-lg-12">
                                    <div class="card">

                                        {/* <!-- end card header --> */}

                                        <div class="card-body">
                                            <div class="report">
                                                <form >
                                                    <div class="row">
                                                        <div class="col-md-6 col-lg-6">
                                                            <div class="mb-3 ">
                                                                <label for="ProjectManager" class="form-label mb-0">Search Identifiers </label>
                                                                <textarea class="form-control" id="exampleFormControlTextarea5" rows="3"></textarea>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-6 col-lg-6">
                                                            <div class="mb-3 ">
                                                              <div class='mt-8_rec'>
                                                                    <button type="submit" class="btn btn-primary mx-2 ">Search</button>
                                                                    <button type="submit" class="btn btn-primary mx-2 ">Clear</button>
                                                                    <button type="submit" class="btn btn-primary mx-2 ">Reconcile</button>
                                                                    <button type="submit" class="btn btn-primary mx-2 ">Complete</button>
                                                                    <button type="submit" class="btn btn-primary mx-2 "><i className ="ri-folder-download-fill "></i> </button>
                                                                    </div>
                                                            </div>
                                                        </div>
                                                        <div class='row justify-content-between mt-3'>
                                                            <div class="col-md-2 col-lg-2">
                                                                <div class="mb-3 d-flex page_size_support">
                                                                    <label for="ProjectManager" class="form-label mb-0 mt-1 ">Page Size : </label>
                                                                    <select id="ForminputState" class="form-select" data-choices data-choices-sorting="true">
                                                                        <option value={""} disabled selected>1</option>
                                                                        <option>10</option>
                                                                        <option>20</option>
                                                                        <option>30</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 col-lg-4 ">
                                                                <div class="text-end">
                                                                    <div class='search '>
                                                                        <input type="text" className="form-control" placeholder="Search..." autocomplete="off" id="search-options" value="" />

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <!--end col--> */}


                                                        {/* <!--end col--> */}
                                                        <div className='table-responsive'>
                                                        <table class="table table-striped table-hover table_client mt-3">
                                <thead>
                                    <tr class="bg-primary" style={{color:"white"}}>
                                        <th scope="col">Sr.No.</th>
                                        <th scope="col">Action</th>
                                        <th scope="col">ProjectCode</th>
                                        <th scope="col">ProjectName</th>
                                        <th scope="col">Supplier </th>
                                   
                                        <th scope="col">SupplierIdentifier</th>
                                        <th scope="col">UserIdentifier</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Geo.Location</th>
                                        <th scope="col">DeviceType</th>
                                        <th scope="col">BrowserDetail</th>
                                        <th scope="col">IsTestLink</th>
                                                                           </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">1</th> 
                                        
                                        <td> <i class ="ri-edit-2-line"></i></td>
                                        <td>0</td>
                                        <td> ABC</td>
                                        <td>CT278</td>
                                        <td>0</td>
                                        <td>2</td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                       
                                    </tr>
                                 

                                </tbody>
                            </table>
                            </div> 




                                                        {/* <!--end col--> */}
                                                    </div>
                                                    {/* <!--end row--> */}
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                {/* <!-- end col --> */}









                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Reconciliation