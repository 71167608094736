import React, { useState } from 'react'
import Footer from '../Layout/Footer'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import { useEffect } from 'react'
import { queries } from '@testing-library/react'
import Question from './Question'
import { MultiSelect } from "react-multi-select-component";
import { addQuestionTPreScreenTemplate, preScreenSearchQuestion, PreScreenTemplateDetail, preScreenTemplatenameUpdate, preScreenTemplateQueDelete, updateQuePriority } from '../../Api/PreScreen'
import { masterData } from '../../Api/Master'
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import TextInput from 'react-autocomplete-input';
import 'react-autocomplete-input/dist/bundle.css';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { parse } from 'date-fns'


function Prescreen_update() {
    const navigate = useNavigate();
    const location = useLocation();
    const queryString = new URLSearchParams(location.search)
    const id = queryString.get('id')

    const [templateName, setTemplateName] = useState();
    const [detail, setDetail] = useState()
    const [languages, setLanguages] = useState();
    const [selectedLanguage, setSelectedLanguage] = useState(303) //For enaglish
    const [questions, setQuestions] = useState([])
    const [priority, setPriority] = useState();
    const [searchQuesOpt, setSearchQueOpt] = useState();
    const [selectedQuestion, setSelectedQuestion] = useState()

    useEffect(() => {
        document.title = "Update Prescreen Template  - Mindforce"
    },);

    useEffect(() => {
        templateDetail()
    }, [])

    const templateDetail = async () => {
        let payload = {
            templateID: id
        }
        let res = await PreScreenTemplateDetail({ payload: payload })
        if (res.status === 1) {
            setDetail(res.payload[0])
            setTemplateName(res?.payload?.[0]?.title)
        }
    }

    const updateTemplateName = async () => {
        let payload = {
            templateName: templateName,
            id: id
        }
        let res = await preScreenTemplatenameUpdate({ payload: payload })
        if (res.status === 1) {

        }
    }

    useEffect(() => {
        getLanguages();
    }, [])

    const getLanguages = async () => {
        let res = await masterData({ moduleName: 'language', countryID:0})
        if (res.status === 1) {
            setLanguages(res.payload)
        }
    }

    const searchQuestion = async (e) => {
        let keyword = e.target.value
        let payload = {
            q: keyword,
            languageID: selectedLanguage
        }
        let res = await preScreenSearchQuestion({ payload })
        if (res.status === 1) {
            setQuestions(res.payload)
            let que = [];
                res.payload.forEach(ele => {
                que.push({title:ele.title, id:ele.ID})
            });
            setSearchQueOpt(que);
        }
    }

    const addQuestion = async () => {
        let payload = {
            templateID: id,
            questions: [
                {
                    ID: selectedQuestion?.ID,
                    priority: 1
                }
            ]
        }
        let res = await addQuestionTPreScreenTemplate({payload:payload})
        console.log('res',res)
        if(res.status === 1){
            templateDetail()
        }
    }

    const deleteTempQue = async(qId) => {
        let payload = {
            templateID:id,
            questionID:qId
        }
        let res = await preScreenTemplateQueDelete({payload:payload})
        if(res.status === 1){
            templateDetail()
        }
    }

    const queDragStart = (e, pos) => {
        console.log('start', e)
    }
    const queDragEnter = (e, pos) => {
        setPriority(pos)
    }
    const queDragEnd = async (e, pos, que) => {
        let questions = detail?.questions;
        questions?.splice(pos, 1);
        questions.splice(priority, 0, que)
        let ques = [];
        questions?.map((q,i) => {
            ques.push({title:q?.title, priority: i+1, ID:q?.questionID})
        })
        let payload = {
            templateID: id,
            questions: ques
        }
        console.log('payload', payload)
        let res = await updateQuePriority({payload:payload})
        if(res?.status === 1){
            templateDetail();
        }
    }
    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            <div class="row">
                                <div class="col-12">
                                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 class="mb-sm-0">Update Prescreen Template</h4>

                                        <div class="page-title-right">
                                            <ol class="breadcrumb m-0">
                                                <li class="breadcrumb-item"><a >Library</a></li>
                                                <li class="breadcrumb-item active">Update Prescreen Template</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}

                            <div class="row">

                                <div class="col-xl-12 col-lg-12">
                                    <div className='d-flex align-items-center justify-content-between mb-2'>  <button className="btn btn-primary" style={{ cursor: "pointer" }}><span className="  ri-arrow-left-fill"></span></button>
                                        <button className="btn btn-primary" ><i className="ri-refresh-line"></i></button>
                                    </div>
                                    <div class="card">

                                        {/* <!-- end card header --> */}

                                        <div class="card-body">
                                            <div class="report">
                                                <form >
                                                    <div class="row">

                                                        <div className='row justify-content-between'>
                                                            <div class="col-md-4">
                                                                <div class="mb-3">
                                                                    <label class="form-label mb-0">Template Name:</label>
                                                                    <div className='d-flex'>
                                                                        <input type="text" class="form-control" id="templatename" defaultValue={templateName} onChange={(e) => setTemplateName(e.target.value)} />
                                                                        <span class="btn btn-primary mx-2" onClick={() => updateTemplateName()}>Update</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 align-self-end">
                                                                <div class="mb-3 align-self-end">
                                                                    <label for="ForminputState" class="form-label mb-0 ">Language:</label>
                                                                    <select className="form-select" data-choices data-choices-sorting="true" id="Language" onChange={(e) => setSelectedLanguage(e.target.value)}>
                                                                        <option disabled selected>Select Language</option>
                                                                        {languages?.map((row) => {
                                                                            return (<option value={row.id} selected={row.id === selectedLanguage ? true : false}>{row.name}</option>)
                                                                        })}

                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-8">
                                                                <div class="mb-3 optionListContainer_div">
                                                                    {/* <label for="ForminputState" class="form-label mb-0">Search Question: </label> */}
                                                                    <div className='d-flex autocompleteUi mt-2'>
                                                                        {/* <input type="text" class="form-control"  id="Searchquestion"
                                                                disabled={selectedLanguage ? false : true}
                                                                placeholder={selectedLanguage ? 'Search Question' : 'Please select language before search question'}
                                                                onChange={(e) => searchQuestion(e)}
                                                                /> */}
                                                                        {/* 
                                                                        <TextInput trigger={["", "@"]} options={["What is tour age ?", "Where are you from ?", "What is your name ?"]} /> */}
                                                                      <Autocomplete
                                                                            freeSolo
                                                                            id="Search"
                                                                            size="small"
                                                                            disableClearable
                                                                            onKeyDown={(e)=>searchQuestion(e)}
                                                                            options={questions}
                                                                            onChange={(e,v) => setSelectedQuestion(v)}
                                                                            getOptionLabel={(questions)=>questions.title +':'+ questions.question|| ""}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    label="Search Question"
                                                                                    InputProps={{
                                                                                        ...params.InputProps,
                                                                                        type: 'search',
                                                                                    }}
                                                                                    value={params?.ID}
                                                                                    // onChange={(e) => test(e)}
                                                                                />
                                                                            )}
                                                                            style={{width:"425px"}}
                                                                        />
                                                                    
                                                                        <span class="btn btn-primary mx-2" style={{cursor:"pointer"}} onClick={()=>addQuestion()}>Add</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        {/* <!--end col--> */}


                                                        {/* <!--end col--> */}
                                                        <div className='table-responsive'>

                                                            <table class="table table-striped table-hover table_client mt-3">
                                                                <thead>
                                                                    <tr class="bg-primary" style={{ color: "white" }}>
                                                                        <th scope="col">Sr.No.</th>

                                                                        <th scope="col" style={{ textAlign:"left" }}>Title</th>
                                                                        <th scope="col" style={{ textAlign:"left" }}>Question</th>
                                                                        <th scope="col">Action</th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {detail?.questions?.map((q, i) => {
                                                                        return (<>
                                                                            <tr>
                                                                                <th scope="row">{i + 1}</th>
                                                                                <td style={{ textAlign:"left" }}>{q.title}</td>
                                                                                <td style={{ textAlign:"left" }}> {q.question}</td>
                                                                                <td>
                                                                                    {/* <span className="p-1"><i className="ri-eye-fill  pointerC" ></i></span> */}
                                                                                    <span className="p-1"><i className=" ri-drag-move-2-line pointerC" 
                                                                                    onDragStart={(e) => queDragStart(e,i)}
                                                                                    onDragEnter={(e) => queDragEnter(e,i)}
                                                                                    onDragEnd={(e)=>queDragEnd(e,i, q)}
                                                                                    key={i}
                                                                                     draggable
                                                                                    ></i></span>
                                                                                    <span className="p-1"><i className="ri-delete-bin-2-line del pointerC" onClick={()=>deleteTempQue(q?.questionID)}></i></span>

                                                                                </td>
                                                                            </tr>
                                                                        </>)
                                                                    })}

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        {/* <!--end col--> */}
                                                    </div>
                                                    {/* <!--end row--> */}
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end col --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Prescreen_update