import React, {useEffect, useState} from 'react'
import { ProjectDetail, ProjectSupplierList, ProjectSupplierListMaster, ProjectUpdateStatusSingle } from '../../Api/Project';
import Footer from '../Layout/Footer'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import { useNavigate, useLocation } from "react-router-dom";
import { masterData } from '../../Api/Master';
import { CModal, CModalHeader, CModalTitle, CModalBody } from '@coreui/react'
import Prescreen_Tab from './Prescreen_Tab';
import Survey_LInk from './TabSingle/Survey_Link';
import TabSingleDetail from './TabSingle/detail';
import TabSingleSecurity from './TabSingle/security';
import TabSingleReportDownload from './TabSingle/report';
import TabSingleSupplerMapped from './TabSingle/supplierMapped';
import TabSinglePreScreen from './TabSingle/prescreenSingle';


function Project_detail() {
    
    const navigate = useNavigate();
    const location = useLocation();
    const queryString = new URLSearchParams(location.search)
    const id = queryString.get('id')
    useEffect(() => {
        document.title = "Project Detail - Mindforce"
    },);
    const [filter, setFilter] = useState('Project_Detail')
    const [detail, setDetail] = useState()
    const [mappedSupplierList, setMappedSupplierList] = useState();
    const [visibleSupplierDetail, setVisibleSupplierDetail] = useState(false)
    const [supplierList, setSupplierList] = useState()
    const [supplierDetail, setSupplierDetail] = useState()
    
    const [reactionTypes, setRectionsType] = useState()
    
    const [status, setStatus] = useState();
    const [testSurveyLink, setTestSurveyLink] = useState()
    const [editSurveyLink, setEditSurveylink] = useState(false);
    const [showSecurityFilterTab, setShowSecurityFilterTab] = useState(false);
    const [showPreScreenFilterTab, setShowPreScreenFilterTab] = useState(false);
    const [redirectLinks, setRedirectLinks] = useState()

    const handleFilter = async (e) => {
        setFilter(e.target.value)
        if (e.target.value === 'Supplier_Mapping') {
            getProjectSupplierList()
            let resSupplier = await ProjectSupplierListMaster();
            let resReactionType = await masterData({ moduleName: 'redirection-type' })
            if (resReactionType.status === 1) {
                setRectionsType(resReactionType.payload)
            }
            if (resSupplier.status === 1) {
                setSupplierList(resSupplier.payload)
            }
        }
        if (e.target.value === 'Survey_Link') {
            // getSurveyLinkDetail()
        }
    }

    const getProjectSupplierList = async () => {
        let res = await ProjectSupplierList({ projectId: id })
        if (res.status === 1) {
            await setMappedSupplierList(res.payload)
        }
    }

    useEffect(() => {
        projectDetailSingle()
        getProjectSupplierList()
    }, [])

    const projectDetailSingle = async () => {
        let res = await ProjectDetail({ payload: id })
        if (res.status === 1) {
            setDetail(res.payload[0])
            setRedirectLinks(res?.redirectLinks)
            setStatus(res.payload[0]?.status)
            res.payload[0]?.securityFilters.forEach(ele => {
                if (ele?.status === 1) {
                    setShowSecurityFilterTab(true)
                }
            });
            res.payload[0]?.projectFilters.forEach(ele => {
                if (ele?.status === 1 && ele.id === 418) {
                    setShowPreScreenFilterTab(true)
                }
            });

        }
    }

    const updateStatus = async () => {
        let res = await ProjectUpdateStatusSingle({ id: id, status: status })
        if (res?.status === 1) {
            projectDetailSingle()
        }
    }  
    
    const handleTestSurveyLink = (link)=>{
        setTestSurveyLink(link)
    }

    const handleEditSureyLink = () => {
        setFilter('Survey_Link')
        setEditSurveylink(true)
        // return (<Survey_LInk detail={detail} updateTestlink={handleTestSurveyLink} editSurveyLink={true} />)
    }

    const handleUpdateEditSurvey = (val) =>{
        setEditSurveylink(val)
    }
    
    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid ">
                            <div className="row">
                                <div className="col-12 col-lg-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Project Detail</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a >All Projects</a></li>
                                                <li className="breadcrumb-item active">Project Detail</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                            <div className='project_detail_topsec'>
                                <div>  <button className="btn btn-primary" onClick={() => navigate('/all-project')} style={{ cursor: "pointer" }}><span className="  ri-arrow-left-fill"></span></button>
                                </div>
                                <div className=''>
                                    {detail?.status === 'active' ? (
                                        <>
                                            <h5> <span className="badge bg-success active_btn"> a </span>
                                                {' ' + detail?.projectCode} : {detail?.fullName}
                                            </h5>
                                        </>
                                    ) : (<>
                                        {detail?.status === 'inactive' ? (<>
                                            <h5> <span className="badge bg-warning inactive_btn"> a </span>
                                                {' ' + detail?.projectCode} : {detail?.fullName}
                                            </h5>
                                        </>) : (<>
                                            {detail?.status === 'invoiced' ? (<>
                                                <h5> <span className="badge Invoiced_btn"> a </span>
                                                    {' ' + detail?.projectCode} : {detail?.fullName}
                                                </h5>
                                            </>) : (<>
                                                {detail?.status === 'closed' ? (<>
                                                    <h5> <span className="badge bg-danger closed_btn"> a </span>
                                                        {' ' + detail?.projectCode} : {detail?.fullName}
                                                    </h5>
                                                </>) : (<></>)}
                                            </>)}
                                        </>)}
                                    </>)}
                                </div>
                            </div>
                            <div className="projectdetail_active_btn d-flex ">
                                <div className='tabs_ui'>
                                    <input type="radio" className="btn-check" name="status" id="option1" value="Project_Detail" onClick={(e) => handleFilter(e)} autocomplete="off" />
                                    <label className={filter === "Project_Detail" ? "btn btn-primary outline_desh" : "btn btn-primary"} for="option1" style={(filter === "Project_Detail" ? { backgroundColor: "#405189", color: "white" } : { backgroundColor: "white", color: "black" })}>Project Detail</label>

                                    <input type="radio" className="btn-check " name="status" id="option2" value="Survey_Link" onClick={(e) => handleFilter(e)} autocomplete="off" />
                                    <label className={filter === "Survey_Link" ? "btn btn-primary outline_desh" : "btn btn-primary"} for="option2" style={(filter === "Survey_Link" ? { backgroundColor: "#405189", color: "white" } : { backgroundColor: "white", color: "black" })}>Survey Link</label>
                                    {showSecurityFilterTab ? (<>
                                        <input type="radio" className="btn-check" name="status" id="option3" value="Security" onClick={(e) => handleFilter(e)} autocomplete="off" />
                                        <label className={filter === "Security" ? "btn btn-primary outline_desh" : "btn btn-primary"} for="option3" style={(filter === "Security" ? { backgroundColor: "#405189", color: "white" } : { backgroundColor: "white", color: "black" })}>Security</label>
                                    </>) : (<></>)}

                                    {showPreScreenFilterTab ? (<>
                                        <input type="radio" className="btn-check" name="status" id="option6" value="PreScreen" onClick={(e) => handleFilter(e)} autocomplete="off" />
                                        <label className={filter === "PreScreen" ? "btn btn-primary outline_desh" : "btn btn-primary"} for="option6" style={(filter === "PreScreen" ? { backgroundColor: "#405189", color: "white" } : { backgroundColor: "white", color: "black" })}>PreScreen</label>
                                    </>) : (<></>)}

                                    <input type="radio" className="btn-check" name="status" id="option4" value="Supplier_Mapping" onClick={(e) => handleFilter(e)} autocomplete="off" />
                                    <label className={filter === "Supplier_Mapping" ? "btn btn-primary outline_desh" : "btn btn-primary"} for="option4" style={(filter === "Supplier_Mapping" ? { backgroundColor: "#405189", color: "white" } : { backgroundColor: "white", color: "black" })}>Supplier Mapping</label>

                                    <input type="radio" className="btn-check" name="status" id="option5" value="Project_Report" onClick={(e) => handleFilter(e)} autocomplete="off" />
                                    <label className={filter === "Project_Report" ? "btn btn-primary outline_desh" : "btn btn-primary"} for="option5" style={(filter === "Project_Report" ? { backgroundColor: "#405189", color: "white" } : { backgroundColor: "white", color: "black" })}>Project Report</label>
                                </div>
                                <div className='search' style={{ display: "flex" }}>
                                    {filter === "Project_Detail" &&
                                        <div className="btn-group ">
                                            <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" onChange={(e) => setStatus(e.target.value)}>
                                                <option value="active" selected={detail?.status === 'active' ? true : false}>Active</option>
                                                <option value="inactive" selected={detail?.status === 'inactive' ? true : false}>In Active</option>
                                                <option value="closed" selected={detail?.status === 'closed' ? true : false}>Closed</option>
                                                <option value="invoiced" selected={detail?.status === 'invoiced' ? true : false}>Invoiced</option>
                                            </select>
                                        </div>
                                    }
                                    {filter === "Project_Detail" && <button className="btn btn-primary" onClick={() => updateStatus()}>Update</button>}
                                    {filter === "Project_Detail" ? (<>
                                        <button className="btn btn-primary" onClick={() => navigate('/single-project-edit?id=' + id, { state: id })}><i className="ri-edit-2-line"></i></button>
                                        <button className="btn btn-primary"  onClick={() => navigate('/clone-project?id=' + id, { state: id })} ><i className=" ri-file-copy-line"></i></button>
                                    </>) : (<></>)}
                                    
                                    {filter === "Survey_Link" && <>{testSurveyLink ? (<><a href={testSurveyLink} target="__blank"><button className="btn btn-primary">Test Survey</button></a></>) : (<></>)}</>}

                                    {filter === "Survey_Link" && editSurveyLink === false ? (<>
                                        <button className="btn btn-primary" onClick={() => handleEditSureyLink()}><i className="ri-edit-2-line"></i></button>
                                    </>) : (<></>)}

                                    <button className="btn btn-primary" onClick={() => window.location.reload()}><i className="ri-refresh-line"></i></button>
                                </div>

                            </div>
                            <div className="row ">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">

                                        {/* <!-- end card header --> */}
                                        {filter === "Project_Detail" &&
                                            <TabSingleDetail detail={detail} mappedSupplierList={mappedSupplierList} redirectLinks={redirectLinks} />
                                        }
                                        {filter === "Survey_Link" &&
                                            <Survey_LInk detail={detail} updateTestlink={handleTestSurveyLink} updateEditSurvey={handleUpdateEditSurvey} editSurveyLink={editSurveyLink} />
                                        }
                                        {filter === "Security" &&
                                            <TabSingleSecurity detail={detail} />
                                        }
                                        {filter === "Supplier_Mapping" &&
                                            <TabSingleSupplerMapped  
                                                detail={detail}
                                                mappedSupplierList={mappedSupplierList} 
                                                showPreScreenFilterTab={showPreScreenFilterTab}
                                                showSecurityFilterTab={showSecurityFilterTab}
                                                supplierList={supplierList}
                                                getProjectSupplierList={getProjectSupplierList}
                                                reactionTypes={reactionTypes}
                                             />
                                        }

                                        {filter === "Project_Report" &&
                                            <TabSingleReportDownload id={id} detail={detail} />
                                        }

                                        {
                                            filter === "PreScreen" &&
                                            // <Prescreen_Tab detail={detail} />
                                            <TabSinglePreScreen detail={detail} />
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />

            
        </div>
    )
}

export default Project_detail