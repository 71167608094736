import React from 'react'
import Footer from '../Layout/Footer'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import { useEffect } from 'react'

const datatemp = [
    {
        status: 'C',
        Title : '	Complete',
        Description: 'Survey Completed',
        Redirect  : 'C'
    } ,
    {
        status: 'T',
        Title : '	Terminate',
        Description: '	Client side Terminate',
        Redirect  : 'T'
    } ,
    {
        status: 'Q',
        Title : '	Over Quota',
        Description: 'Client side Over Quota',
        Redirect  : 'Q'
    } ,
    {
        status: 'F',
        Title : '		Quality Terminate',
        Description: '	Client side Quality Terminate',
        Redirect  : 'F'
    } ,
    {
        status: 'D',
        Title : '	 Drop Out',
        Description: 'Client side Drop Out',
        Redirect  : 'NA'
    } ,
    {
        status: 'A',
        Title : '	Active',
        Description: 'Active',
        Redirect  : 'NA'
    } ,
    {
        status: 'R',
        Title : '		Rejected Identifier',
        Description: '	Token Upload',
        Redirect  : 'NA'
    } ,
    {
        status: 'QC',
        Title : '	Duplicate Supplier User',
        Description: 'Duplication – Supplier UID',
        Redirect  : 'F'
    } ,
    {
        status: 'QA',
        Title : '		Duplicate IP',
        Description: 'Unique IP',
        Redirect  : 'F'
    } ,
    {
        status: 'QE',
        Title : '	GEO IP Mismatch',
        Description: 'GEO IP Mismatch',
        Redirect  : 'F'
    } ,
    {
        status: 'QD',
        Title : '	Device Validation',
        Description: '	Device Type ',
        Redirect  : 'F'
    } ,
    {
        status: 'BI',
        Title : '	Blocked IP',
        Description: 'User coming from Blocked IP, Redirect to Supplier QualityTerm',
        Redirect  : 'F'
    } ,
    {
        status: 'SF',
        Title : 'Speeder Validation',
        Description: 'If user completes survey before allowed Speeder timeframe',
        Redirect  : 'F'
    } ,
    {
        status: 'EX',
        Title : '		Exclude',
        Description: 'If user from Excluded Project with defined Status takes survey will be redirected to Supplier Terminate',
        Redirect  : 'F'
    } ,

    {
        status: 'PS',
        Title : '		PrescreenStart',
        Description: '	Whenever user lands to Prescreener make entry in UserSurvey',
        Redirect  : 'NA'
    } ,

    {
        status: 'QP',
        Title : '	Prescreener Terminate',
        Description: 'Pre Screener Terminate',
        Redirect  : 'T'
    } ,

    {
        status: 'OD',
        Title : '	Prescreener Over Quota',
        Description: '	Prescreener over quota',
        Redirect  : 'Q'
    } ,

    {
        status: 'PD',
        Title : '	Prescreener Drop Out',
        Description: 'Prescreener Drop Out',
        Redirect  : 'NA'
    } ,

    {
        status: 'SC',
        Title : '		Survey Closed',
        Description: '	Survey Closed',
        Redirect  : 'SC'
    } ,
    {
        status: 'OC',
        Title : '		Survey Inactive',
        Description: '	Survey Inactive',
        Redirect  : 'SC'
    } ,
    {
        status: 'OE',
        Title : '	Supplier Traffic Disable',
        Description: '',
        Redirect  : 'SC'
    } ,
    {
        status: 'NA',
        Title : '		Project Complete Exhausted',
        Description: '	ProjectCompleteUserSurveyCount>SampleSize',
        Redirect  : 'Q'
    } ,
    {
        status: 'NC',
        Title : '	Project Click Exhausted',
        Description: 'ProjectSurveyLogCount>Project.RespondentClickQuota',
        Redirect  : 'Q'
    } ,
    {
        status: 'NB',
        Title : '	Supplier Complete Exhausted',
        Description: 'ProjectSupplierCompleteUserSurveyCount>PSM.SupplierQuota',
        Redirect  : 'Q'
    } ,
    {
        status: 'ND',
        Title : '	Supplier Click Exhausted',
        Description: 'ProjectSupplierSurveyLogCount>PSM.SupplierQuota',
        Redirect  : 'Q'
    } ,
    {
        status: 'OH',
        Title : '	Multi Link Not Avalilable',
        Description: '',
        Redirect  : 'NA'
    } ,
    {
        status: 'RT',
        Title : '	Identifier Not Exists (Recontact)',
        Description: '',
        Redirect  : 'F'
    } ,
    {
        status: 'FU',
        Title : '		Fraud User',
        Description: 'UserIdentifier and IP combination generated SHA-1 Hashing using slId as key to verify authenicated user, Redirect to Supplier QualityTerm',
        Redirect  : 'F'
    } ,
    {
        status: 'MF',
        Title : '	MSign Failure',
        Description: 'Identify invalid user checks using Tor, Unknown/obselete user agent, taking survey in offtimezone, Browser and User IP CountryCode are same',
        Redirect  : 'F'
    } ,
    {
        status: 'RF',
        Title : '	RD Failure',
        Description: '	User not passed from Reserach Defender API, Redirect to Supplier QualityTerm',
        Redirect  : 'F'
    } ,




]

function Redirect_status() {
    useEffect(() => {

        document.title = "Redirect Status - Mindforce"



    },);
    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            <div class="row">
                                <div class="col-12">
                                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 class="mb-sm-0">Redirect Status</h4>

                                        <div class="page-title-right">
                                            <ol class="breadcrumb m-0">
                                                <li class="breadcrumb-item"><a >Support</a></li>
                                                <li class="breadcrumb-item active">Redirect Status</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}

                            <div class="row ">
                                <div class="col-xl-12 col-lg-12">
                                    <div class="card table_redirect">

                                        {/* <!-- end card header --> */}

                                        <div class="card-body">
                                            <div class="report ">
                                                <form >
                                                    <div class="row">
                                                       

     


                                                        {/* <!--end col--> */}

                                                        <table class="table table-striped table-hover table_client ">
                                                            <thead>
                                                                <tr class="bg-primary" style={{ color: "white" }}>
                                                                    <th scope="col">Sr. No.</th>
                                                                    
                                                                    <th style={{ textAlign:"left" }} scope="col">Status</th>
                                                                    <th style={{ textAlign:"left" }} scope="col">Title</th>
                                                                    <th style={{ textAlign:"left" }} scope="col">Description</th>
                                                                   
                                                                    <th  scope="col">Redirect To Supplier Status</th>
                                                                   
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {datatemp.map ((item,i) =>{
                                                                    return (
                                                                     <tr>
                                                                    <th scope="row">{i+1}</th>

                                                                    
                                                                    <td style={{ textAlign:"left" }}>{item.status}</td>
                                                                    <td style={{ textAlign:"left" }}>{item.Title}</td>
                                                                    <td style={{ textAlign:"left" }}>{item.Description}</td>
                                                                    <td > {item.Redirect}</td>
                                                                   

                                                                </tr>)
                                                                })}
                                                               
                                                               

                                                            </tbody>
                                                        </table>

                    


                                                        {/* <!--end col--> */}
                                                    </div>
                                                    {/* <!--end row--> */}
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                {/* <!-- end col --> */}









                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Redirect_status