import React from 'react'
import Footer from '../../Layout/Footer'
import Header from '../../Layout/Header'
import Sidebar from '../../Layout/Sidebar'
import Preloader from '../../Layout/Preloader';
import Select from 'react-dropdown-select';
import { useEffect, useState, useRef } from 'react'
import { getProjectActivityReport } from '../../../Api/Project'
import { getAllProjectReport } from '../../../Api/Report'

function ReportActivity_report() {
    useEffect(() => {
        document.title = "Project Activity Report - Mindforce"
    },);
    const [ProjectActivityReportView, setProjectActivityReportView] = useState([]);
    const [lists, setLists] = useState();
    const [selProjectID, setProjectID] = useState();

    useEffect(() => {
        getReport();
    }, []);

    const getDownloadReport = async(e) => {
        e.preventDefault();
        let prjIds = selProjectID?.split('-');
        let payload = {
            "projectDetailID": prjIds[1],
            "viewType": 'download'
        }
        let resClntRpt = await getProjectActivityReport({payload});
        if(resClntRpt.status === 1){
            window.open(resClntRpt?.payload?.reportURL);
        } 
    };

    const getViewReport = async(e) => {
        e.preventDefault();
        let prjIds = selProjectID?.split('-');
        let payload = {
            "projectDetailID": prjIds[1],
            "viewType": 'list'
        }
        let resClntRpt = await getProjectActivityReport({payload});
        if(resClntRpt.status === 1){
            setProjectActivityReportView(resClntRpt?.payload)
        }
    };

    const getReport = async() => {
        let res = await getAllProjectReport();
        if (res.status === 1) {
            let prjList = [];

            res.payload?.map((row) => {
                    prjList.push(
                        {
                            "projectDetailID": row.projectType + '-' + row.projectDetailID,
                            "projectDetailFullName": row.projectDetailCode + ': ' + row.projectDetailFullName + ' - ' + row.countryName,
                        }
                    )
                }
            )
            setLists(prjList);
        } else 
        setLists([]);
    }

    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            <div class="row">
                                <div class="col-12 col-lg-12">
                                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 class="mb-sm-0">Project Activity Report</h4>

                                        <div class="page-title-right">
                                            <ol class="breadcrumb m-0">
                                                <li class="breadcrumb-item"><a >Report</a></li>
                                                <li class="breadcrumb-item active">Project Activity Report</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}

                            <div class="row">
                                <div class="col-xl-12 col-lg-12">
                                    <div class="card">

                                        {/* <!-- end card header --> */}

                                        <div class="card-body">
                                            <div class="report">
                                                <form >
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <label for="ForminputState" class="form-label mb-0">Project <span class="required_field">*</span></label>
                                                                    <Select
                                                                        className="form-control"
                                                                        options={lists}
                                                                        labelField="projectDetailFullName"
                                                                        valueField="projectDetailID"
                                                                        searchBy="projectDetailFullName"
                                                                        onChange={(values) => setProjectID(values[0]?.projectDetailID)}
                                                                        placeholder="Select Project"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4 col-lg-4">
                                                        <div class="report_btn">
                                                                <button type="submit" class="btn btn-primary" onClick={(e) => getViewReport(e)}>View</button>
                                                                <button type="submit" class="btn btn-primary mx-2" onClick={(e) => getDownloadReport(e)}>Download</button>
                                                                {/* <button type="submit" class="btn btn-primary" onClick={(e) => getClearSelection(e)}>Reset</button> */}
                                                               
                                                            </div>
                                                        </div>
                                                        {/* <!--end col--> */}
                                                       
                                                     
                                                        {/* <!--end col--> */}
                                                       


                                                       


                                                        {/* <!--end col--> */}
                                                    </div>
                                                    {/* <!--end row--> */}
                                                    <div className='row mt-3'>
                                                        {ProjectActivityReportView ? (
                                                                <>
                                                                <div className='hScrollDiv'>
                                                                    <table className="table table-striped table-hover  table_client">
                                                                        {
                                                                            ProjectActivityReportView?.length > 0 ? (
                                                                                <>
                                                                                    <thead>
                                                                                        <tr className="bg-primary" style={{ color: "white" }}>
                                                                                            <th>S.No.</th>
                                                                                            <th style={{ textAlign:"left" }}>ActivityType</th>
                                                                                            <th style={{ textAlign:"left" }}>Date</th>
                                                                                            <th style={{ textAlign:"left" }}>User</th>
                                                                                            <th>Page Name</th>
                                                                                            <th>Field Name</th>
                                                                                            <th>Previous Value</th>
                                                                                            <th>New Value</th>
                                                                                            <th>Supplier Name</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            ProjectActivityReportView?.map((row, i) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <tr>
                                                                                                            <td scope="row">{i + 1}</td>
                                                                                                            <td style={{ textAlign:"left" }}>{row?.activityType}</td>
                                                                                                            <td style={{ textAlign:"left" }}>{row?.date}</td>
                                                                                                            <td style={{ textAlign:"left" }}>{row?.createdBy}</td>
                                                                                                            <td>{row?.pageName}</td>
                                                                                                            <td>{row?.fieldName}</td>
                                                                                                            <td>{row?.oldValue}</td>
                                                                                                            <td>{row?.newValue}</td>
                                                                                                            <td>{row?.supplierName}</td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                );
                                                                                            })
                                                                                        }
                                                                                    </tbody>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                   <tr>
                                                                                        <td colSpan={13}>
                                                                                            <center>No project activity data found</center>
                                                                                        </td>
                                                                                    </tr> 
                                                                                </>
                                                                            )
                                                                        }
                                                                    </table>
                                                                </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Preloader />
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                {/* <!-- end col --> */}









                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ReportActivity_report